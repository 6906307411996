*,
*::after,
*::before,
body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent; /* this is remove background color when user click where have cursor: pointer */
    font-size: unset;
}

h1,h2,h3,h4,h5,h6,p,span {
    padding: 0;
    margin: 0;
}

ul,ol {
    padding: 0;
    margin: 0;
    list-style: none;
}

a {
    text-decoration: none;
}

html {
    scroll-behavior: smooth;
    font-size: 10px;
}

:focus-visible{
    outline: none;
}

button:disabled, button[data-disabled] {
    cursor: not-allowed !important;
    pointer-events: auto !important;
    border-color: transparent !important; 
    background-color: #DDDDDD !important; 
    color: #adb5bd !important; 
}

:root {
    --font-regular : 'Montserrat';
    --font-medium : 'Montserrat-Medium';
    --font-semibold : 'Montserrat-SemiBold';
    --font-bold : 'Montserrat-Bold';

    --fs-m-8 : 8px var(--font-medium);
    --fs-r-8 : 8px var(--font-regular);
    --fs-sb-8 : 8px var(--font-semibold);
    --fs-m-10 : 10px var(--font-medium);
    --fs-r-10 : 10px var(--font-regular);
    --fs-sb-10 : 10px var(--font-semibold);
    --fs-b-10 : 10px var(--font-bold);
    --fs-r-12 : 12px var(--font-regular);
    --fs-m-12 : 12px var(--font-medium);
    --fs-b-12 : 12px var(--font-bold);
    --fs-sb-13 : 13px var(--font-semibold);
    --fs-r-14 : 14px var(--font-regular);
    --fs-m-14 : 14px var(--font-medium);
    --fs-sb-14 : 14px var(--font-semibold);
    --fs-r-16 : 16px var(--font-regular);
    --fs-sb-16 : 16px var(--font-semibold);
    --fs-m-16 : 16px var(--font-medium);
    --fs-b-16 : 16px var(--font-bold);
    --fs-sb-18 : 18px var(--font-semibold);
    --fs-r-20 : 20px var(--font-regular);
    --fs-sb-22 : 22px var(--font-semibold);
    --fs-sb-25 : 25px var(--font-semibold);
    --fs-sb-30 : 30px var(--font-semibold);
}   

body{
	margin:0;
	position: relative;
}

.container {
    max-width: 1170px;
    margin: auto;
}

.d-none{
    display: none !important;
}

.line-seperator{
    margin: 20px 0 20px 0;
}

.uppercase {
    text-transform: uppercase;
}

::-webkit-scrollbar {
    width: 5px;
    height: 8px;
}

::-webkit-scrollbar-thumb {
    background: #888; 
}

::-webkit-scrollbar-thumb:hover {
    background: #555; 
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}
.w-100 {
    width: 100%;
}

.cursorPointer {
    cursor: pointer;
}

.whiteSpaceNowrap {
    white-space: nowrap;
    overflow: hidden;
    flex: 1;
}

.d_flex {
    display: flex;
}

.flex_1 {
    flex: 1
}

.mt_4 {
    margin-top: 0.4rem
}
.mt_8 {
    margin-top: 0.8rem
}
.mt_12 {
    margin-top: 1.2rem
}
.mt_10{
    margin-top: 1rem
}
.mt_16 {
    margin-top: 1.6rem
}
.mt_20 {
    margin-top: 2.0rem
}
.mt_25 {
    margin-top: 2.5rem
}

.mb_4 {
    margin-bottom: 0.4rem
}
.mb_8 {
    margin-bottom: 0.8rem
}
.mb_10{
    margin-bottom: 1rem
}
.mb_12 {
    margin-bottom: 1.2rem
}
.mb_16 {
    margin-bottom: 1.6rem
}
.mb_20 {
    margin-bottom: 2rem
}

.mr_4 {
    margin-right: 0.4rem
}
.mr_8 {
    margin-right: 0.8rem
}
.mr_12 {
    margin-right: 1.2rem
}
.mr_16 {
    margin-right: 1.6rem
}
.mr_20 {
    margin-right: 2rem
}
.mr_50 {
    margin-right: 5rem
}
.mr_100 {
    margin-right: 10rem
}

.pt_1{
    padding-top: 1rem
}
.pl_22{
    padding-left: 2.2rem
}

.pb_1{
    padding-bottom: 1rem
}
.pr_22{
    padding-right: 2.2rem
}

.shape_circle{
    border-radius: 50%;
}

.text_align_center{
    text-align: center;
}

.paragraph_max_line_1{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.paragraph_max_line_2{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}