
.loader-wrapper-main .loader-wrapper {
  z-index: 9999;
  opacity: 0.75;
  transform: scale(1);
  background: black;
}

.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: all 0.1s linear;
  opacity: 0;
  height: 100vh;
  transform: scale(10);
  z-index: -1;
}

.loader-wrapper .loader01 .loader01-last {
  animation-play-state: paused;
  border: 5px solid #c8c8c8;
  border-radius: 50%;
  animation: rotate 1s infinite linear;
  border-left-color: rgba(200, 200, 200, 0.4);
  width: 100%;
  height: 100%;
}

.loader-wrapper .loader01 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border-radius: 50%;
  box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.8);
  width: 60px;
  height: 60px;
  padding: 5px;
}

.loader-wrapper-main .loader-wrapper .loader01 .loader01-last {
  animation-play-state: running;
}

@keyframes loadicons {
  0% {
    transform: scale(0) translate3d(0, 0, 0);
  }
  11% {
    transform: scale(1.2) translate3d(0, 0, 0);
  }
  22% {
    transform: scale(1) translate3d(0, 0, 0);
  }
  33% {
    transform: scale(0) translate3d(0, 0, 0);
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.loader-wrapper-main .loader-wrapper .loader01 img {
  font-size: 24px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  color: #fff;
  width: 24px;
  height: 24px;
  line-height: 1;
  transform: scale(0) translate3d(0, 0, 0);
}

.loader-wrapper-main .loader-wrapper .loader01 img:nth-of-type(1) {
  animation: loadicons 3s infinite ease-in-out;
}

.loader-wrapper-main .loader-wrapper .loader01 img:nth-of-type(2) {
  animation: loadicons 3s 1s infinite ease-in-out;
}

.loader-wrapper-main .loader-wrapper .loader01 img:nth-of-type(3) {
  animation: loadicons 3s 2s infinite ease-in-out;
}

.loader-wrapper-main .loader-wrapper .loader02 img {
  font-size: 24px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  color: #fff;
  width: 50px;
  height: 50px;
  line-height: 1;
}

.loader-wrapper-main .loader-wrapper .loader03 img {
  font-size: 24px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  color: #fff;
  width: 75px;
  height: 75px;
  line-height: 1;
}

.loader-dots{
  display: flex;
  justify-content: center;
  margin: 5px;
}

.loader-dots svg{
  fill: var(--theme-active);
}

/** Swal Popup */
.swal2-popup.swal2-toast .swal2-title{
  font: var(--fs-sb-13) !important;
}

/** Modal **/

.standard-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000000b8;
  z-index: 1000;
  overflow-y: auto;
}

.standard-popup-modal-container {
  background: 0 0;
  transform: translate(0,0);
  margin: 10rem auto;
  padding: unset;
  background: var(--modal-body-bg);
  min-width: 300px;
  max-width: 504px;
  border-radius: 10px;
}

.standard-popup-modal-container .standard-popup-modal-header {
  position: relative;
  width: 100%;
  text-align: center;
  border-top: transparent;
  border-left: transparent;
  border-right: transparent;
  border-bottom: transparent;
  background-color: var(--modal-header-bg);
  color: var(--modal-header-color);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: center;
}

.standard-popup-modal-container .standard-popup-modal-header .standard-modal-title {
  font: var(--fs-m-16);
  text-overflow: ellipsis;
  overflow: hidden;
  width: 90%;
  padding: 10px 0;
}

.standard-popup-modal-container .standard-popup-modal-header .standard-modal-close {
  position: absolute;
  right: 5px;
  top: 3px;
  background: 0 0;
  font-size: 25px;
  padding: 0px 14px;
  border-radius: 1px;
  cursor: pointer;
  color: undefined;
}

.standard-popup-modal-container .standard-popup-modal-body {
  font-weight: 400;
  background: var(--modal-body-bg);
  color: var(--modal-text-color);
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  border-left: transparent;
  border-right: transparent;
  border-radius: 10px;
}

.standard-popup-modal-container .standard-popup-modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.standard-popup-modal-container .standard-popup-modal-footer .standard-modal-close:disabled {
  cursor: not-allowed;
  background: #f67e03;
  opacity: unset;
}

.standard-popup-modal-container .standard-popup-modal-footer .standard-modal-close {
  padding: 1px 15px;
  min-width: 0.8rem;
  background: #bf0916;
  border: transparent;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}


.apply-detail-modal-body{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.apply-detail-modal-body,.apply-detail-modal-body p,.apply-detail-modal-body h1,.apply-detail-modal-body h2,.apply-detail-modal-body h3,.apply-detail-modal-body h4,.apply-detail-modal-body h5{
  font : var(--fs-r-14);
  margin-bottom: 10px;
  color: var(--modal-text-color);
}

.standard-popup-modal-body table td{
  padding: 4px !important;
  border: #161212 1px solid;
  font : var(--fs-r-14);
  color: var(--modal-text-color);
}

.standard-popup-modal-body ul li,.standard-popup-modal-body ol li{
  margin-bottom: 10px;
  font : var(--fs-r-14);
}

.standard-popup-modal-body table{
  width: 100% !important;
  margin-top: 10px;
  margin-bottom: 10px;
}

.standard-popup-modal-body table tbody tr:first-child {
  background: var(--table-header-bg);
  color:var(--table-header-color);
  font-weight: 600;
}

.apply-detail-modal-image{
  margin-bottom:10px;
}

.apply-detail-modal-image img{
  width: 100%;
}

.apply-detail-modal-footer{
  display: flex;
  padding-bottom: 5px;
}

.apply-detail-modal-footer a{
  display: flex;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 35px;
  font: var(--fs-m-14);
}

.apply-detail-modal-apply {
  width: 95%;
  background: var(--submit-btn-bg2);
  color : var(--submit-btn-color2);
}

.apply-detail-modal-copy {
  background: var(--submit-btn-bg);
  color : var(--submit-btn-color);
  width: 5%;
  margin-left: 5px;
  
}

.apply-detail-modal-container{
  min-width: 300px;
  max-width: 900px;
  height: 95%;
  margin-top: 1%;
  margin-bottom: 0;
  overflow-y: hidden;
  background: var(--modal-body-bg) !important;
  border-radius: 10px;
}

.apply-detail-modal-container .standard-popup-modal-body{
  height: inherit;
  background: var(--modal-body-bg) !important;
}

/** Language **/
.langpop {
  padding: 15px 25px;
  box-sizing: border-box;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.langpop .region-split{
	display: flex;
	flex-flow: row;
	align-items: center;
	padding: 20px 0;
}

.langpop .region-thumb{
	width: 40px;
	height: 40px;
	margin-right: 30px;
}

.langpop .region-thumb img{
	width: 100%;
}

.langpop .region-right{
	display: flex;
	flex-flow: column;
	justify-content: space-between;
}

.langpop .region-name{
	font: var(--fs-sb-16);
	color: var(--theme-color2);
	text-align: left;
	margin: 0;
}

.langpop .lang-selection{
	display: flex;
	flex-flow: row;
	list-style: none;
	padding: 0;
	margin: 0;
	align-items: center;
}

.langpop .lang-selection li{
	padding: 0 20px;
}

.langpop .lang-selection li:not(:last-child){
	border-right: 1px solid var(--theme-color2);
}

.langpop .lang-selection li:last-child{
	padding-right: 0;
}

.langpop .lang-selection li:first-child{
	padding-left: 0;
}

.langpop .lang-selection li a{
	font: var(--fs-m-16);
	color: var(--modal-text-color);
	text-decoration: none;
}

.langpop .lang-selection li a:hover{
	text-decoration: underline;
}

.langpop .lang-selection li a.active{
	font-weight: 800;
	color: var(--theme-active);
}

/** Input Form */
.form-wrapper .form-control{
  width: 100%;
}

.form-wrapper .form-control.readonly input{
  background-color: #e7e7e7;
}

.form-wrapper .form-group.dial-code .form-control:first-child{
  width: 25%;
}

.form-wrapper .form-group.dial-code .form-control:nth-child(2){
  width: 74%;
}

.form-wrapper .form-group{
  margin-bottom: 15px;
}

.form-wrapper .form-group.split{
  display: flex;
  gap: 2%;
}

.form-wrapper .form-group.split div{
  flex:1;
}

.form-wrapper .form-group.dial-code{
  display: flex;
  justify-content: space-between;
}

.form-wrapper .form-group .text-note{
  font: var(--fs-r-12);
  color: var(--theme-color2);
}

.form-wrapper .form-control .mantine-InputWrapper-label, .form-wrapper .form-group .standard-input-label,.form-wrapper .form-group .mantine-Select-label{
  color: var(--theme-color2);
  margin-bottom: 8px;
  font: var(--fs-sb-14);
}

.standard-popup-modal-body .form-wrapper .form-control .mantine-InputWrapper-label,.standard-popup-modal-body .form-wrapper .form-group .standard-input-label,.standard-popup-modal-body .form-wrapper .form-group .mantine-Select-label{
  color: var(--modal-text-color);
}

.form-wrapper .form-control input{
  font: var(--fs-r-14);
  color: #000;
  height: calc(1.5em + 0.75rem + 10px);
  border-radius: 7px;
}

.form-wrapper .form-control .mantine-PasswordInput-wrapper{
  border-radius: 7px;
}

.form-wrapper .form-control .mantine-PasswordInput-input,.form-wrapper .form-control .mantine-Select-input,.providerwallet-type-filter input,.form-wrapper .form-control .mantine-NumberInput-input,.slot-search .filter-input-box input{
  /* background-color: #e7e7e7; */
}

.form-wrapper .form-control .mantine-Input-rightSection .mantine-ActionIcon-root:hover{
  background-color :transparent;
}

.form-wrapper .form-control .mantine-TextInput-icon svg, .form-wrapper .form-control .mantine-Input-rightSection svg{
  font-size: 15px;
  color: var(--theme-color2);
}

.form-wrapper .form-group .submit-btn{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 35px;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  margin-right: 5px;
  text-transform: uppercase;
  font: var(--fs-m-12);
  background: var(--submit-btn-bg);
	color: var(--submit-btn-color);
}

.form-wrapper .form-group .submit-btn.secondary-btn{
  background: var(--submit-btn-bg2);
	color: var(--submit-btn-color2);
}

.form-wrapper .mantine-Stepper-steps{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.form-wrapper .mantine-Stepper-steps .mantine-Stepper-stepIcon{
  border-color: var(--theme-color2);
  background-color: transparent;
  color: var(--theme-color2);
}

.form-wrapper .mantine-Stepper-steps .mantine-Stepper-stepIcon,
.form-wrapper .mantine-Stepper-steps .mantine-Stepper-stepIcon .mantine-Stepper-stepCompletedIcon{
  color: var(--theme-color2);
}

.form-wrapper .mantine-Stepper-steps .mantine-Stepper-separator{
  height: 2px;
  min-height: 2px;
  background-color: #e9ecef;
  margin-left: 16px;
  margin-right: 16px;
}

.form-wrapper .form-control .dial-code-dropdown img{
  width: 30px;
  height: 30px;
  margin: 0;
  padding: 0;
}

.no-record-found{
  font: var(--fs-m-16);
  color: var(--theme-color2);
}

/** Element **/
.standard-btn{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  margin-right: 5px;
  text-transform: uppercase;
  min-width: 95px;
  height: 35px;
  font : var(--fs-r-12);
  padding: 10px;
  cursor: pointer;
}

/* .mantine-Drawer-root .mantine-Text-root{
  font: var(--fs-m-14);
  padding: 5px;
} */

.auto-transfer-wrapper {
  display: flex;
  align-items: center;
}

.auto-transfer-wrapper .mantine-Switch-input{
  display: none;
}

.auto-transfer-wrapper span{
  font : var(--fs-r-14);
  color: var(--theme-color2);
  margin-left: 5px;
}

/** Pagination */
.pagination-wrapper{
  display: flex;
  justify-content: center;
  padding: 10px;
  width: 100%;
}

.pagination-wrapper .pagination{
  display: flex;
  flex-flow: row wrap;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: start;
  justify-content: flex-start;
  gap: 8px;
}

.pagination-wrapper .pagination .page-item{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  min-width: 32px;
  padding: 0px 8px;
  border-radius: 4px;
  line-height: 1;
  font: var(--fs-r-16);
  /* border: 1px solid rgb(206, 212, 218);
  color: rgb(0, 0, 0);
  background-color: rgb(255, 255, 255); */
}

.pagination-wrapper .pagination .page-item.active{
  color: #fff;
  background-color: var(--theme-active);
  border-radius: 50px;
}

.pagination-wrapper .pagination .page-item .page-icon{
  display: flex;
  align-items: center;
}

.pagination-wrapper .pagination .page-item .page-num{
  display: flex;
  align-items: center;
}

.feed.swiper .swiper-pagination-bullets,
.recommand-game-content .swiper-pagination-bullets {
  display: none;
}

.feed.swiper .btn {
  cursor: pointer;
}

.gameproviders-list li{
  margin: 0 15px;
}

.gameproviders-list li img{
  width: 80px;
}

.submit-btn:disabled {
  cursor: not-allowed !important;
  pointer-events: auto !important;
  background: rgb(233, 236, 239) !important;
  color: rgb(173, 181, 189) !important; 
}


@keyframes shockwaveJump {
	0% {
		-webkit-transform: scale(1);
				transform: scale(1);
	}
	40% {
		-webkit-transform: scale(1.08);
				transform: scale(1.08);
	}
	50% {
		-webkit-transform: scale(0.98);
				transform: scale(0.98);
	}
	55% {
		-webkit-transform: scale(1.02);
				transform: scale(1.02);
	}
	60% {
		-webkit-transform: scale(0.98);
				transform: scale(0.98);
	}
	100% {
		-webkit-transform: scale(1);
				transform: scale(1);
	}
}