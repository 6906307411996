/* any-pointer is find point or touch on screen( mobile = touch / desktop = pointer )  */
@media only screen and (min-width: 769px) and (max-height: 961px) and (pointer: fine) {
    :root{
        font-size: 1.0406vh;
    }
}

@media only screen and (min-width: 769px) and (max-height: 961px) and (pointer: fine) {
    .vehicleBrands_hotUpcoming_itemBox_content_bottom_row{
        align-items: flex-start;
        flex-direction: column;
    }
    .vehicleBrands_hotUpcoming_itemBox_content_top{
        width: 100%;
        justify-content: center;
        height: auto;
    }
    .vehicleBrands_hotUpcoming_itemBox_content{
        font-size: 1.72vh;
    }
    .vehicleNewsItemBox{
        max-width: 25rem;
    }
    .modal_vehicleModal_variantTop_imageOuter{
        width: 14rem;
        height: 9.206rem;
    }
    .homePage_popularBox_tabItem_text{
        font-size: 1.4rem;
    }
    .homePage_rankingBox_logo{
        width: 11rem;
    }
}

@media only screen and (max-width: 1024px) {
    :root{
        font-size: 10px;
    }
    .phoneFrameContainer{
        visibility: hidden;
    }
    .mobileBodyContainer{
        height: unset;
        min-height: unset;
    }
    .contentContainer{
        padding-top: 0;
        padding-bottom: 0;
        overflow: unset;
    }
    .bottomNavigationMobile{
        padding-bottom: 5.5rem;
    }
    .modal_vehicleRoot{
        position: fixed;
    }
    .compareBottom{
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: white;
    }
    .header{
        position: fixed;
        padding-top: 1.6rem;
    }
    .headerBack{
        position: fixed;
        padding-top: 2rem;
    }
    .bodyContainer{
        padding-top: 6.4rem;
    }
    .bodyContainerWithSubTab{
        padding-top: 8.4rem;
    }
    .bodyContainerHasBottomNavigation {
        padding-bottom: 5.5rem;
    }
    .bottomNavigation{
        padding-bottom: 0rem;
        position: fixed;
        bottom: -0.1rem;
        left: 0;
        right: 0;
    }
    .vehiclePage_lookCar_button{
        font-size: 2.9vw;
    }
    .compareVehicle_vsContainer_text{
        font-size: 13vw;
    }
    .modal_vehicleModalStickyHeader{
        top: 0;
    }
    .modal_vehicleModalStickyHeader .headerBack{
        margin-top: 0rem;
        position: unset;
    }
    .vehicle_brandlist_sideBar{
        max-height: calc(100vh - 20rem);
    }
    .vehicleFilterHeader_leftCat{
        position: fixed;
        top: 6.4rem;
    }
    .compareVehicleDetail_header{
        position: sticky;
        top: 0;
    }
    .vehicleFilter_bottomNavigation{
        padding-bottom: 0rem;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .profile_userVehicle_list_bottom{
        padding-bottom: 0rem;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .tyreFilter_bottomNavigation{
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .vehicleFilterResult_bottom{
        position: fixed;
        bottom: 0;
        width: 100%;
        background-color: #FFF;
    }
    .privacyPolicyContentBox_content{
        min-height: 50rem;
    }
    .center_modal_mantine_root{
        position: fixed
    }

    @keyframes animationUp {
        0% { transform: translateY(calc(100% - 12.8rem)) }
        100% { transform: translateY(7rem) }
    }
    
    @keyframes animationDown {
        0% { transform: translateY(7rem) }
        100% { transform: translateY(calc(100% - 12.8rem))}
    }

    /*  */
    .stick_bottom{
        padding-bottom: 0rem;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .map_info_dropdown{
        top: 1.4rem
    }
    .google_map_page{
        position: fixed;
        top: 6.4rem;
        bottom: 0;
        left: 0;
        right: 0;
        overflow-y: hidden;
        height: unset;
    }
    .google_map_outlet{
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .google_map_loading{
        top: 1.4rem
    }
    .addMyVehicleDrawer_root{
        position: fixed;
    }
    .addMyVehicleDrawer_drawer{
        position: fixed;
    }
    .commentDrawer_root{
        position: fixed;
    }
    .commentDrawer_drawer{
        position: fixed;
    }
    .addArchiveVehicleDrawer_root{
        position: fixed;
    }
    .addArchiveVehicleDrawer_drawer{
        position: fixed;
    }
    .selectOptionDrawer_root{
        position: fixed;
    }
    .selectOptionDrawer_drawer{
        position: fixed;
    }
    .voucherDetail_bottomNavigation{
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: white;
    }
}

/* @media only screen and (orientation: landscape) and (pointer: coarse) {
    :root{
        font-size: 10px;
    }
    .phoneFrameContainer{
        visibility: hidden;
    }
    .mobileBodyContainer{
        height: unset;
        min-height: unset;
    }
    .contentContainer{
        padding-top: 0;
        padding-bottom: 0;
        overflow: unset;
    }
    .modal_vehicleRoot{
        position: fixed;
    }
    .compareBottom{
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: white;
    }
    .header{
        position: fixed;
        padding-top: 1.6rem;
    }
    .headerBack{
        position: fixed;
        padding-top: 2rem;
    }
    .bodyContainer{
        padding-top: 6.4rem;
    }
    .bodyContainerWithSubTab{
        padding-top: 8.4rem;
    }
    .bodyContainerHasBottomNavigation {
        padding-bottom: 5.5rem;
    }
    .bottomNavigation{
        padding-bottom: 0rem;
        position: fixed;
        bottom: -0.1rem;
        left: 0;
        right: 0;
    }
    .vehiclePage_lookCar_button{
        font-size: 2.9vw;
    }
    .compareVehicle_vsContainer_text{
        font-size: 13vw;
    }
    .modal_vehicleModalStickyHeader{
        top: 0;
    }
    .modal_vehicleModalStickyHeader .headerBack{
        margin-top: 0rem;
        position: unset;
    }
    .vehicle_brandlist_sideBar{
        max-height: calc(100vh - 20rem);
    }
    .vehicleFilterHeader_leftCat{
        position: fixed;
        top: 6.4rem;
    }
    .compareVehicleDetail_header{
        position: sticky;
        top: 0;
    }
    .vehicleFilter_bottomNavigation{
        padding-bottom: 0rem;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .profile_userVehicle_list_bottom{
        padding-bottom: 0rem;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .tyreFilter_bottomNavigation{
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .vehicleFilterResult_bottom{
        position: fixed;
        bottom: 0;
        width: 100%;
        background-color: #FFF;
    }
    .privacyPolicyContentBox_content{
        min-height: 50rem;
    }
    .center_modal_mantine_root{
        position: fixed
    }

    @keyframes animationUp {
        0% { transform: translateY(calc(100% - 12.8rem)) }
        100% { transform: translateY(7rem) }
    }
    
    @keyframes animationDown {
        0% { transform: translateY(7rem) }
        100% { transform: translateY(calc(100% - 12.8rem))}
    }

    .stick_bottom{
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .map_info_dropdown{
        top: 1.4rem
    }
    .google_map_page{
        position: fixed;
        top: 6.4rem;
        bottom: 0;
        left: 0;
        right: 0;
        overflow-y: hidden;
        height: unset;
    }
    .google_map_outlet{
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .google_map_loading{
        top: 1.4rem
    }
    .addMyVehicleDrawer_root{
        position: fixed;
    }
    .addMyVehicleDrawer_drawer{
        position: fixed;
    }
    .commentDrawer_root{
        position: fixed;
    }
    .commentDrawer_drawer{
        position: fixed;
    }
    .addArchiveVehicleDrawer_root{
        position: fixed;
    }
    .addArchiveVehicleDrawer_drawer{
        position: fixed;
    }
    .selectOptionDrawer_root{
        position: fixed;
    }
    .selectOptionDrawer_drawer{
        position: fixed;
    }
    .voucherDetail_bottomNavigation{
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: white;
    }
} */

@media only screen and (max-width: 425px) {
    :root{
        font-size: 2.353vw;
    }
}

@media only screen and (max-width: 374px) {
    .vehicleBrands_hotUpcoming_itemBox_content_bottom_row{
        align-items: flex-start;
        flex-direction: column;
    }
    .vehicleBrands_hotUpcoming_itemBox_content_top{
        width: 100%;
        justify-content: center;
        height: auto;
    }
    .vehicleBrands_hotUpcoming_itemBox_content_bottom{
        padding-top: 0rem;
    }
    .vehicleNewsItemBox{
        max-width: 26rem;
    }
}