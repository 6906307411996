:root {
    --body-bg : #FFFFFF;
    --header-bg: #FFFFFF;
    --theme-bg : #0051d6;
    --theme-color : #FFFFFF;
    --theme-color2 : #000000;
    --theme-box-bg : #FFFFFF;
    --theme-box-bg2 : #f5f5f5;
    --theme-active : #ff8a00;
    --theme-active2 : #0051d6;
    --theme-linear-bg : linear-gradient(90deg, #124292 0%, #0071e3 100%);

    --seperator-boder : 1px solid rgba(17 17 17 / 0.1);

    --submit-btn-bg : #0051d6;
    --submit-btn-color : #FFFFFF;

    --submit-btn-bg2 : #ff8a00;
    --submit-btn-color2 : #000000;

    --submit-btn-bg3 : #0051d6;
    --submit-btn-color3 : #FFFFFF;

    --navbar-bg: #000f4C;
    --navbar-color : #FFFFFF;
    --navbar-item-color : #FFFFFF;
    --navbar-inner-bg : #000f4CCE;
    --navbar-border : 4px solid #FFFFFF;

    --modal-header-bg : #0051d6;
    --modal-header-color : #ffffff;
    --modal-body-bg : #ffffff;
    --modal-text-color : #000000;

    --nav-bg : #0051d6;
    --nav-bg-active : #ff8a00;
    --nav-color : #ffffff;
    --nav-color-active : #000000;

    --table-header-bg : #0051d6;
    --table-header-color : #ffffff;

    /*  */
    --app-height: 100vh;


    --color-red: #E91C21;
    --color-red-hover: #C5181C;
    --color-red-active: #A01519;
    --color-red2: #FF8386;
    --color-red3: #FFD1D1;
    --color-red4: #FFECEC;
    --color-red5: #FF8686;
    --color-lightRed: #F0454B;
    --color-lightRed2: #FFDFE0;

    --color-lightBlue: #6CC1FF;
    --color-lightBlue2: #98D4FF;
    --color-lightBlue3: #CFF4FF;
    --color-lightBlue4: #99D8EA;
    --color-lightBlue5: #E6FAFF;
    --color-blue: #0094FF;
    --color-blue2: #5EA7BC;
    --color-darkBlue: #005B75;
    --color-darkBlue2: #007DA5;

    --color-grey2: #939393;
    --color-grey3: #ABABAB;
    --color-grey4: #888888;
    --color-grey5: #909090;
    --color-grey6: #8F8F8F;
    --color-lightGrey: #F2F2F2;
    --color-lightGrey2: #B3B3B3;
    --color-lightGrey3: #C4C4C4;

    --color-green: #279700;
    --color-lightGreen: #DFFFD0;
    

    --textInput-bg: #F0F0F0;
    --textInput-placeholderColor: #B7B7B7;
    --textInput-color: #000000;

    --login-box-bg: #FFF;

    --drawer-closeButton-color: #E91C21;
    --date-color: #ABABAB;

    --newsCategory-inactive-color: #888888;
    --newsCategory-active-color: #000000;

    --newsSubCategory-active-bg: #007DA5;
    --newsSubCategory-active-color: #FFFFFF;
    --newsSubCategory-inactive-bg: #E5F9FF;
    --newsSubCategory-inactive-color: #007DA5;

    --nav-btm-active-bg: #E91C21;
    --nav-btm-active-color: #FFFFFF;
    --nav-btm-inactive-bg: #fff;
    --nav-btm-inactive-color: #AAAAAA;

    --newsTag-color: #005B75;
    --newsTag-linear: linear-gradient(135deg, #99D8EA 0%, #D1F5FF 100%);

    --viewBox-grey-bg: #EDEDED;
    --viewBox-grey-color: #AAAAAA;

    --viewBox-red-bg: #FFF3F3;
    --viewBox-red-color: #FFA09E;

    --hottest-linear: linear-gradient(135deg, #FFCBCB 0%, #FFECDA 100%);
    --recommendedCar-linear: linear-gradient(180deg, #CDF4FF 0%, #FFFFFF 100%);
    --vehicleBrands-linear: linear-gradient(179deg, #D2F5FF 0%, #FFF 100%);
    --vehicleModel-linear: linear-gradient(179deg, #D2F5FF 0%, #FFF 100%);

    /* --vehicleModelTypeButton-linear-blue: linear-gradient(100deg, #99D8EA 0%, #ACEDFF 48.44%, #CFF4FF 100%); */
    --vehicleModelTypeButton-linear-blue: linear-gradient(100deg, #49cff5 0%, #6cd9f6 48.44%, #98e1f7 100%);
    --vehicleModelTypeButton-linear-red: linear-gradient(90deg, #FF383D 0%, #FF9559 100%), linear-gradient(100deg, #99D8EA 0%, #ACEDFF 48.44%, #CFF4FF 100%);
    /* --vehicleModelTypeButton-linear-green: linear-gradient(100deg, #20DFBA 0%, #34F1CC 48.44%, #9BFFEB 100%), linear-gradient(90deg, #FF383D 0%, #FF9559 100%), linear-gradient(100deg, #99D8EA 0%, #ACEDFF 48.44%, #CFF4FF 100%); */
    --vehicleModelTypeButton-linear-green: linear-gradient(100deg, #20DFBA 0%, #36f4cf 48.44%, #6cedd4 100%), linear-gradient(90deg, #FF383D 0%, #FF9559 100%), linear-gradient(100deg, #99D8EA 0%, #ACEDFF 48.44%, #CFF4FF 100%);
    --vehicleModelTypeButton-linear-dark: linear-gradient(90deg, rgba(101,101,101,1) 0%, rgba(50, 50, 50, 0.5) 100%);
    --vehicleModelTypeButton-linear-yellow: linear-gradient(90deg, rgb(35 0 255) 0%, rgb(118 148 255) 100%);
    --vehicleModelTypeButton-linear-purple: linear-gradient(90deg, rgb(143 45 234) 0%, rgb(192 117 239) 100%);

    --vehicleBrandTitle-linear: linear-gradient(90deg, #D9D9D9 0%, rgba(217, 217, 217, 0.00) 100%);

    --bottomNavigation-bg: #FFFFFF;
    --bottomNavigation-inactive-color: #AAA;
    --bottomNavigation-active-color: #E91C21;
    --bottomNavigation-button-active: #E91C21
}
