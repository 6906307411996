.maintenance-wrapper{
    text-align: center; 
    padding: 10px; 
    margin: 0px auto;
}

.maintenance-wrapper img{
    width: 300px;
}

.maintenance-wrapper h1{
    font: var(--fs-sb-25);
    color:var(--theme-color);
}

.maintenance-wrapper .maintenance-content{
    display: block;
    text-align: center;
    width: 100%;
    margin: 0px auto;
}

.maintenance-wrapper .maintenance-content p{
    font: var(--fs-r-20);
    color:var(--theme-color);
}