.error-wrapper{
  height: 500px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.error-wrapper h1{
  padding: 10px 0 5px 0;
  color: var(--theme-color2);
  font:var(--fs-b-16);
}

.error-wrapper p{
  padding: 10px 0 20px 0;
  color: var(--theme-color2);
  font:var(--fs-r-12);
}
.error-wrapper .action-btn{
 display: flex;
}

.error-wrapper .action-btn a{
  background: var(--submit-btn-bg);
  color: var(--submit-btn-color);
}

.error-wrapper .action-btn a:nth-of-type(2){
  background: var(--submit-btn-bg2);
  color: var(--submit-btn-color2);
}