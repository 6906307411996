@font-face {
    font-family: 'Montserrat';
    src: url(../fonts/Montserrat-Regular.ttf);
}

@font-face {
    font-family: 'Montserrat-Medium';
    src: url(../fonts/Montserrat-Medium.ttf);
}

@font-face {
    font-family: 'Montserrat-SemiBold';
    src: url(../fonts/Montserrat-SemiBold.ttf);
    font-weight: bold;
}

@font-face {
    font-family: 'Montserrat-Bold';
    src: url(../fonts/Montserrat-Bold.ttf);
    font-weight: bold;
}

/*  */

@font-face {
    font-family: 'Poppins';
    src: url(../fonts/Poppins-Regular.ttf);
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url(../fonts/Poppins-Medium.ttf);
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url(../fonts/Poppins-SemiBold.ttf);
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url(../fonts/Poppins-Bold.ttf);
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url(../fonts/Poppins-ExtraBold.ttf);
    font-weight: 800;
    font-style: normal;
}

/* @font-face {
    font-family: 'Poppins';
    src: url(../fonts/Poppins-italic.ttf);
    font-weight: 400;
    font-style: italic;
} */

@font-face {
    font-family: 'Poppins';
    src: url(../fonts/Poppins-BoldItalic.ttf);
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url(../fonts/Poppins-ExtraBoldItalic.ttf);
    font-weight: 800;
    font-style: italic;
}