
/* # Base =======================  */
/* typography.css */
@import url(_typography.css);
/* normalize.css */
@import url(_normalize.css);

/* # Modules ==========================  */
/* footer.css */
@import url(_footer.css);
/* header.css */
@import url(_header.css);

/* # Components =======================  */
@import url(_components.css);

/* # Pages ==========================  */
@import url(_error.css);
@import url(_maintenance.css);

/* Main Css */
@import url(main.css);

a:active, a:focus, a:hover{
    text-decoration: unset;
}
p+p{
    margin-top: unset;
}

p {
    font-size: 1.4rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    color: #000000;
}

/*  font  */
.fontSize_36{
    font-size: 3.6rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    color: #000000;
}
.fontSize_28{
    font-size: 2.8rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    color: #000000;
}
.fontSize_25{
    font-size: 2.5rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    color: #000000;
}
.fontSize_24{
    font-size: 2.4rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    color: #000000;
}
.fontSize_20 {
    font-size: 2rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    color: #000000;
}
.fontSize_18 {
    font-size: 1.8rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    color: #000000;
}
.fontSize_16 {
    font-size: 1.6rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    color: #000000;
}
.fontSize_14 {
    font-size: 1.4rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    color: #000000;
    line-height: 2.4388rem;
}
.fontSize_12 {
    font-size: 1.2rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    color: #000000;
}
.fontSize_10 {
    font-size: 1rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    color: #000000;
}

/* Tempory Use */
.fontSize_24_em{
    font-size: 2.4em !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    color: #000000;
}
.fontSize_16_em{
    font-size: 1.6em !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    color: #000000;
}
.fontSize_14_em{
    font-size: 1.4em !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    color: #000000;
}
.fontSize_12_em{
    font-size: 1.2em !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    color: #000000;
}

/* fontStyle */
/* only got 400 weight and 700 weight and 800 weight have italic */
.fontStyle_italic {
    font-style: italic;
}

/* fontWeight */
.fontWeight_medium {
    font-weight: 500;
}
.fontWeight_semiBold {
    font-weight: 600;
}
.fontWeight_bold {
    font-weight: 700;
}
.fontWeight_extraBold {
    font-weight: 800;
}

/* fontColor */
.fontColor_white {
    color: white;
}
.fontColor_dark {
    color: black;
}
.fontColor_grey {
    color: #7D7D7D;
}
.fontColor_grey2 {
    color: var(--color-grey2)
}
.fontColor_grey3 {
    color: var(--color-grey3)
}
.fontColor_grey4{
    color: var(--color-grey4)
}
.fontColor_grey5{
    color: var(--color-grey5)
}
.fontColor_grey6{
    color: var(--color-grey6)
}
.fontColor_lightGrey {
    color: #B3B3B3;
}
.fontColor_lightGrey2 {
    color: var(--color-lightGrey2);
}
.fontColor_lightGrey3 {
    color: var(--color-lightGrey3);
}
.fontColor_red {
    color: var(--color-red);
}
.fontColor_red2 {
    color: var(--color-red2);
}
.fontColor_red3 {
    color: var(--color-red3)
}
.fontColor_red5{
    color: var(--color-red5)
}
.fontColor_lightRed{
    color: var(--color-lightRed)
}
.fontColor_lightBlue {
    color: var(--color-lightBlue);
}
.fontColor_lightBlue2 {
    color: var(--color-lightBlue2)
}
.fontColor_lightBlue3 {
    color: var(--color-lightBlue3)
}
.fontColor_lightBlue4{
    color: var(--color-lightBlue4)
}
.fontColor_blue {
    color: var(--color-blue);
}
.fontColor_blue2 {
    color: var(--color-blue2)
}
.fontColor_darkBlue{
    color: var(--color-darkBlue)
}
.fontColor_darkBlue2{
    color: var(--color-darkBlue2)
}
.fontColor_green{
    color: var(--color-green)
}

.fontColor_placeHolder{
    color: var(--textInput-placeholderColor);
}
.fontColor_disabled{
    color: #909296 !important
}

/* fontColorHover */
.fontColor_redHover {
    color: var(--color-red);
}
.fontColor_redHover:hover{
    color: var(--color-red-hover);
}
.fontColor_redHover:active {
    color: var(--color-red-active);
}

.backgroundColor_disabled{
    opacity: 0.6;
}

/* button */
.submitLoginButton {
    width: 100%;
    height: 4.5rem;
    border-radius: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: transparent;
    cursor: pointer;
}
.tagButton { 
    height: 2rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    background: var(--newsTag-linear);
    border-radius: 3.1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.tagButton_disabled { 
    height: 2rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    background: unset;
    border-radius: 3.1rem;
    border: 0.1rem solid #99D8EA; 
    display: flex;
    align-items: center;
    justify-content: center;
}
.tagButton_sm {
    height: 2rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
}
.viewButton{
    display: flex;
    align-items: center;
    min-width: 6.8rem;
    height: 2rem;
    justify-content: center;
    border-radius: 3.1rem;
    gap: 0.647rem;
    padding: 0 0.8rem;
}
.viewButton_icon{
    width: 1.4584rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.viewButton_text{
    color: var(--viewBox-grey-color);
}
.viewButton_grey{
    background-color: var(--viewBox-grey-bg);
}
.viewButton_grey .viewButton_text{
    color: var(--viewBox-grey-color);
}
.viewButton_red{
    background-color: var(--viewBox-red-bg);
}
.viewButton_red .viewButton_text{
    color: var(--viewBox-red-color);
}

.positionRelative {
    position: relative;
}
.displayflex{
    display: flex;
}
.justifyContentRight{
    justify-content: flex-end;
}

/* skeleton */
.absoluteSkeletonBox {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}
.skeletonParagraphRowTitle{
    width: 100%;
    height: 2rem;
    padding: 0.3rem 0;
}
.skeletonParagraphRow{
    width: 100%;
    height: 1.8rem;
    padding: 0.3rem 0;
}
.skeletonParagraphRowDate{
    width: 10rem;
    height: 1.6rem;
    padding: 0.3rem 0;
}
.skeletonTagItem{
    min-height: 2.438rem;
    min-width: 8rem;
    position: relative;
}


/* vehicle image mask empty */
.exmptyVehicleImageMask{
    mask-image: url('/assets/images/empty/empty_vehicle.png');
    mask-size: cover;
    width: 100%;
    height: 100%;
}

/*  */
.mainLoaderContainer{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
}

/* primaryButton */
.primaryButtonBackground {
    background-color: var(--color-red);
}
.primaryButtonBackground:hover{
    background-color: var(--color-red-hover);
}
.primaryButtonBackground:active {
    background-color: var(--color-red-active);
}
/* secondaryButton */
.secondaryButtonBackground {
    background-color: #99D8EA;
}
.secondaryButton {
    height: 3.5rem;
    border-radius: 5rem;
    background-color: #99D8EA;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.thirdButton{
    height: 5rem;
    width: 100%;
    border-radius: 5rem;
    background-color: #E6FAFF;
    display: flex;
    align-items: center;
    justify-content: center;
}
.fouthButton{
    width: 100%;
    height: 3.5rem;
    display: flex;
    border-radius: 5rem;
    background-color: #99D8EA;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
}
.fifthButton{
    width: 100%;
    height: 3.5rem;
    display: flex;
    border-radius: 5rem;
    background-color: #007DA5;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
}
.disabledButton{
    width: 100%;
    height: 3.5rem;
    display: flex;
    border-radius: 5rem;
    background-color: #E5E5E5;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
}
.viewMore {
    width: 100%;
    height: 4.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}
.viewMore_icon{
    width: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.compareFixButton{
    position: fixed;
    display: flex;
    background-color: #99d8ea;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    transform: translate(-1rem, -1rem);
}
.compareFixButton_indicator{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    background-color: red;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
}

.compareBottom{
    height: 6.5rem;
    box-shadow: 0rem -0.4rem 1.3rem 0rem rgba(153, 216, 234, 0.48);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 9;
}
.compareBottom_button{
    flex: 1 1;
    background-color: var(--color-red);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4.5rem;
    border-radius: 3rem;
    position: relative;
}
.compareBottom_buttonRight{
    width: 2.8rem;
    height: 2.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    right: 1.1rem;
    transform: translateY(-50%);
    background-color: #FFCBCB;
    border-radius: 50%;
}

/* compare modal */
.compareModal{
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.compareModal_row{
    height: 4.9rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.compareModal_rowLeft{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.compareModal_row_image{
    width: 6.5rem;
    height: 4.9rem;
}
.compareModal_row_closeIcon{
    width: 2.2rem;
    height: 2.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.compareModel_button{
    height: 5.2rem;
    border-radius: 3rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2.5rem;
    border: unset;
    margin-bottom: 3rem;
}

/* placement ads */
.placementBox{
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
}
.placementBox .placementFrontLayer{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0
}
.placementBoxIframe{
    display: flex;
    align-items: center;
    justify-content: center;
}
.placementBoxIframe > iframe {
    width: 300px;
    height: 250px;
}
.placementBoxGoogle{
    /* display: flex; 
    justify-content: center; */
}
.placementBoxGoogle ins.adsbygoogle{
    min-width: 300px;
    /* min-height: 250px; */
}
.placementBoxGoogle ins.adsbygoogle[data-ad-status="unfilled"] {
    display: none !important;
}

/* icon Size */
.compareIcon_size{
    width: 3.2rem;
}
.calculatorButtonIcon_size{
    width: 2.4rem
}
.plusIcon_size{
    width: 4.6rem;
}
.compareIconAdded_size{
    width: 2rem;
    height: 2rem;
}

/* playbutton */
.playButton{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.playButtonCircle{
    
    display: flex;
    align-items: center;
    justify-content: center;
    background: #010101B2;
    border-radius: 50%;
}
.playButtonCircle_md{
    width: 6.6rem;
    height: 6.6rem;
}
.playButtonCircle_sm{
    width: 3.2rem;
    height: 3.2rem;
}
.playButtonCircle_icon_md{
    width: 5.3rem;
}
.playButtonCircle_icon_sm{
    width: 2.7rem;
}

/* VehicleModelTypeButton */
.vehicleModelTypeButton_Box{
    display: flex;
    align-items: center;
    /* gap: 1rem; */
    /* height: 3rem; */
}
.vehicleModelTypeButton{
    /* width: 10.4rem;
    height: 2.4rem;
    border-radius: 5rem;
    display: flex;
    align-items: center;
    justify-content: center; */
    border-radius: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.1rem 0.3rem;
    min-width: 2.5rem;
}
.vehicleModelTypeButton_blue{
    background: var(--vehicleModelTypeButton-linear-blue);
}
.vehicleModelTypeButton_red{
    background: var(--vehicleModelTypeButton-linear-red);
}
.vehicleModelTypeButton_green{
    background: var(--vehicleModelTypeButton-linear-green);
}
.vehicleModelTypeButton_dark{
    background: var(--vehicleModelTypeButton-linear-dark);
}
.vehicleModelTypeButton_yellow{
    background: var(--vehicleModelTypeButton-linear-yellow);
}
.vehicleModelTypeButton_purple{
    background: var(--vehicleModelTypeButton-linear-purple);
}

.imageButton{
    width: 11.9rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.6rem;
    border-radius: 3.1rem;
    background: linear-gradient(135deg, #99D8EA 0%, #D1F5FF 100%), #99D8EA;
    z-index: 2;
}
.imageButton_image{
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
}

.switchButton{
    width: 8.8rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.4rem;
    border-radius: 10rem;
    background: #E91C21;
    z-index: 2;
}
.switchButton_image{
    width: 1.7rem;
    height: 1.7rem;
    display: flex;
    align-items: center;
}

/* mantine custom */
/* slider */
.mantineCustome-Slider-track{
    height: 0.25rem;
}
.mantineCustome-Slider-bar, .mantineCustome-Slider-track::before{
    background-color: var(--color-red);
}
.mantineCustome-Slider-markWrapper{
    top: -0.5rem;
}
.mantineCustome-Slider-markWrapper:first-child{
    transform: translateX(-0.4rem);
}
.mantineCustome-Slider-mark{
    width: 0.1rem;
    height: 1.2rem;
    background-color: #C7C7C7;
    transform: translateY(-0.7rem);
    border: unset;
}
.mantineCustome-Slider-markWrapper:first-child .mantineCustome-Slider-mark, 
.mantineCustome-Slider-markWrapper:last-child .mantineCustome-Slider-mark{
    width: 1.2rem;
    height: 1.2rem;
    border: 0.25rem solid #E91C21;
    background-color: #fff;
    transform: translateX(-0.4rem);
}
.mantineCustome-Slider-thumb{
    color: #E91C21;
    border-color: #E91C21;
    background-color: #E91C21;
}
.mantineCustome-Slider-label{
    top: unset;
    bottom: -3.6rem;
    background-color: unset;
    color: #E91C21;
}
.mantineCustome-Slider-markLabel{
    transform: translate(-45%, -180%);
}
.mantineCustome-Slider-markWrapper:first-child .mantineCustome-Slider-markLabel{
    transform: translate(-25%, -180%);
}
.mantineCustome-Slider-markWrapper:last-child .mantineCustome-Slider-markLabel{
    transform: translate(-80%, -180%);
}

/* Vehicle New */
.vehicleNews{
    margin-top: 3.2rem
}
.vehicleNewsScrollContainer{
    overflow: auto;
    width: 100%;
    padding: 1.7rem 0;
    display: flex;
}
.vehicleNewsScrollContainerWrap{
    display: flex;
    gap: 1.5rem;
    align-items: stretch;
}
.vehicleNewsItem{
    width: 100%;
    /* height: 27.4rem; */
    max-width: 33.5rem;
}
.vehicleNewsItemBox{
    border-radius: 1.5rem;
    height: 100%;
    overflow: hidden;
    max-width: 33.5rem;
    width: 33.5rem;
    box-shadow: 0rem 0rem 1.25rem 0rem rgba(153, 216, 234, 0.48);
    display: flex;
    flex-direction: column;
}
.vehicleNewssItemBox_ImageOuter{
    position: relative;
    width: 100%;
    padding-top: 52.23%
}
.vehicleNewssItemBox_Image{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.vehicleNewssItemBox_Content{
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    height: 100%;
}
.vehicleNewssItemBox_Content_title{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 4.9rem;
}
.vehicleNewssItemBox_ContentBottom{
    min-height: 3rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 0.8rem;
    margin-top: 0.8rem;
}
.vehicleNewssItemBox_ContentBottom_date{
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    width: 8rem;
    height: 2.4rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    white-space: nowrap;
}
.vehicleNewssItemBox_ContentBottom_dateFakePadding{
    width: 8rem;
    height: 2.4rem;
}

/* vehicle user review */
.vehicleUserReview{
    /* padding-top: 3.8rem; */
}
.vehicleUserReview_container{
    padding-top: 1.8rem;
}
.vehicleUserReview_row1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.vehicleUserReview_row2{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.vehicleUserReview_row2_rating{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: 1rem;
}
.vehicleUserReview_row2_rating_num{
    display: flex;
    align-items: center;
    gap: 0.5rem;
    justify-content: flex-start;
}
.vehicleUserReview_row2_rating_num_text{
    white-space: nowrap;
}
.vehicleUserReview_row2_rating_star_iconList{
    display: flex;
    align-items: center;
}
.vehicleUserReview_row2_rating_star_icon{
    width: 1.8rem;
}
.vehicleUserReview_row2_rating_dropdown_icon{
    width: 2.4rem;
}
.vehicleUserReview_row2_button{
    border-radius: 3rem;
    background: #E91C21;
    width: 10.8rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.vehicleUserReview_row3{
    display: flex;
    align-items: center;
    gap: 1.4rem;
}
.vehicleUserReview_row3_left{
    flex: 1;
    display: flex;
    flex-direction: column;
}
.vehicleUserReview_row3_left_row{
    display: flex;
    flex-direction: row;
    gap: 0.8rem;
    flex: 1;
    padding-top: 0.7rem;
}
.vehicleUserReview_row3_left_row p{
    width: 1rem;
    text-align: center;
}
.vehicleUserReview_row3_left_progress{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.vehicleUserReview_row3_right{
    text-align: center;
}

.vehicleUserReview_viewMoreButtonMargin{
    margin-top: 0.9rem
}
.vehicleUserReview_commandList{
    display: flex;
    flex-direction: column;
    gap: 0.9rem;
    padding-top: 0.6rem;
}
.vehicleUserReview_commandItem{
    border-radius: 2rem;
    border: 0.1rem solid #99D8EA;
    padding: 1.1rem 1.6rem .7rem;
}
.vehicleUserReview_commandItem_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.vehicleUserReview_commandItem_ratingList{
    display: flex;
    align-items: center;
}
.vehicleUserReview_commandItem_ratingIcon{
    width: 1.6rem;
}

/* dealer */
.dealer{
    margin-top: 1rem;
}
.dealer_title{
    margin: 0.5rem 0;
}
.dealer_content{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    margin-top: 1.2rem;
}
.dealer_item{
    border-radius: 1.5rem;
    background: #FFF;
    box-shadow: 0rem 0rem 1.3rem 0rem rgba(153, 216, 234, 0.48);
    padding: 1.5rem 1.7rem;
}
.dealer_item_row_1{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.dealer_item_row_1_title{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    flex: 1;
}
.dealer_item_row_1_image{
    width: 3rem;
}
.dealer_item_row_2{
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    margin-top: 0.5rem;
}
.dealer_item_row_2_icon{
    width: 2.4rem;
}
.dealer_item_row_2_text{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    flex: 1;
}
.dealer_item_row_3{
    margin-top: 1.2rem;
}

/* line */
.dividingLinePadding {
    padding: 0 2rem;
}

.dividingLine {
    width: 100%;
    height: 0.1rem;
    background-color: #DDDDDD;
}


/* container */
.bodyContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
    height: 100%;
    position: relative
}
.bodyContainerWithSubTab{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
    height: 100%;
    position: relative
}
.contentContainer{
    height: 100%;
    overflow-y: auto;
    /* padding-top: 11%; */
    /* padding-bottom: 2rem; */
    -ms-overflow-style: none;
    scrollbar-width: none;
    position: relative;
    display: flex;
    flex-direction: column;
}
.contentContainer::-webkit-scrollbar {
    display: none;
}
.containerPadding {
    padding-right: 2rem;
    padding-left: 2rem;
}
.containerPaddingBottom{
    padding-bottom: 2rem;
}
.mobileBodyContainer{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    height: 100vh;
}
.mobilePhoneContainer{
    padding-bottom: 6.5rem;
}

/* phone frame */
.phoneContainer{
    min-height: 100%;
    height: 100%;
}
.phoneFrameContainer{
    display: flex;
    justify-content: center;
    height: 100vh;
    align-items: center;
}
.phoneFrameBox{
    height: 100vh;
    /* padding-left: 49.12%; */
    position:relative;
    overflow: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    max-height: 108rem;
    overscroll-behavior-y: none;
}
.phoneFrameBox::-webkit-scrollbar {
    display: none;
}
.phoneFrameFixed{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.phoneFrame{
    background-image: url('/assets/images/phoneFrame.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    pointer-events: none;
    max-height: 108rem;
    height: 100vh;
    width: 100%;
}
.phoneFrameInner {
    padding: 4% 12% 3.7%;
    height: 100%;
}
.phoneLoadingOverlay{
    border-radius: 8rem;
    overflow: hidden;
}

/* load more */
.loadingMore{
    display: flex;
    height: 5rem;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}
.loadingMore_text{
    color: #7C7C7C
}

/* textInput */
.textInput {
    background-color: var(--textInput-bg);
    width: 100%;
    height: 4.6rem;
    border-radius: 5rem;
    border: none;
    padding-left: 2.2rem;
    padding-right: 2.2rem;
}
.textInput::placeholder, .textInput:-ms-input-placeholder, .textInput::-ms-input-placeholder{
    color: var(--textInput-placeholderColor);
    opacity: 1;
}
.textInputRequestOPT{
    padding-right: 2.2rem;
    width: 100%;
    text-align: right;
    height: 100%;
    border: transparent;
    background: transparent;
    border-radius: 0 5rem 5rem 0;
    cursor: pointer;
}
.textInputError{
    padding-left: 2.2rem;
    padding-right: 2.2rem;
}

/* drawer */
.drawer_body{
    height: 100%;
}
.drawer{
    /* padding: 2rem 0 7.4rem; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    height: 100%;
}
.drawer_closeBox{
    display: flex;
    align-items: center;
    gap: 1.6rem;
    margin: 2rem 2.6rem 3.1rem;
    cursor: pointer;
}
.drawer_closeText{
    color: var(--color-red);
}
.drawer_closeButton{
    width: 2.4rem;
    height: 2.4rem;
    color: var(--color-red);
}
.drawer_closeBox:hover .drawer_closeText, .drawer_closeBox:hover .drawer_closeButton{
    color: var(--color-red-hover);
}
.drawer_closeBox:active .drawer_closeText, .drawer_closeBox:active .drawer_closeButton{
    color: var(--color-red-active);
}
.drawerMenu{
    width: 100%;
    display: flex;
    flex-direction: column;
    height: calc(100% - 9.8rem - 5.5rem);
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    /* position: absolute;
    bottom: 9.8rem;
    top: 5.5rem;
    left: 0;
    right: 0; */
}
.drawerMenu::-webkit-scrollbar {
    display: none;
}
.drawerMenu_list{
    margin: 0 2.6rem;
}
.drawerMenu_item{
    display: flex;
    align-items: center;
    margin-top: 3.1rem;
    cursor: pointer;
}
.drawerMenu_item:first-child{
    margin-top: 0rem;
}
.drawerMenu_languageItemOuter{
    margin-top: 3.1rem;
}
.drawerMenu_languageItem{
    display: flex;
    align-items: center;
    cursor: pointer;
}
.drawerMenu_languageItemRight{
    display: flex;
}
.drawerMenu_languageItemRightText{
    color: #7C7C7C;
}
.drawerMenu_languageItemListRow{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.drawerMenu_itemText{
    /* display: flex; */
    flex: 1
}
.drawerMenu_languageItemText{
    color: #7C7C7C;
    margin-top: 1.2rem;
}
.drawerMedia{
    margin: 3.1rem 2.6rem
}
.drawerMedia_list{
    display: flex;
    align-items: center;
    gap: 1.2rem;
    margin-top: 0.9rem;
}
.drawerBottom{
    width: 100%;
    position: absolute;
    bottom: 0rem;
    background-color: #FFF;
}
.drawerBottomLineOuter{
    padding: 0 2.3rem 0 2.4rem;
}
.drawerBottom_button{
    height: 5.4rem;
    margin: 1.9rem 2.1rem 2.4rem 2.6rem;
    border-radius: 5rem;
    background-color: var(--color-red);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.drawerBottomOuter{
    height: 9.8rem;
    width: 100%;
}

.drawerBottom_profile{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1.1rem;
}
.drawerBottom_profileImage{
    width: 5.7rem;
    height: 5.7rem;    
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
}
.drawerBottom_profileText{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.drawerBottom_profileImage_viewProfile{
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.drawerBottom_profileImage_viewProfile_icon{
    width: 1.6rem;
    height: 1.6rem;
}
.drawer_fuelPrice{
    padding: 2rem 2rem 1rem;
    margin: 0rem 2.6rem 2rem;
    border-radius: 2rem;
    box-shadow: 0rem 0rem 1rem 0rem rgba(125, 201, 254, 0.20);
}
.drawer_fuelPrice_text{
    text-align: center;
}
.drawer_fuelPrice_section{
    margin-top: 0.8rem;
}
.drawer_fuelPrice_item{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.drawer_fuelPrice_item_left{
    display: flex;
    align-items: center;
    gap: 0.8rem
}
.drawer_fuelPrice_item_dot{
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;
}
.drawer_fuelPrice_date_position{
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
}



.media_icon{
    width: 3.5rem;
    cursor: pointer;
}
.expand_icon {
    width: 2.4rem;
    margin-left: 0.4rem;
}
.expand_tick_icon{
    width: 1.4rem;
}






.headerIcon {
    width: 2.4rem;
    height: 2.4rem;
    color: #000;
}
.headerIcon_searchInput{
    width: 1.8rem;
    height: 1.8rem;
    color: var(--color-grey4);
}
.headerBack{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: calc(11% + 2rem);
    padding-bottom: 2rem;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 9;
    background-color: #fff;
    width: 100%;
}
.headerBack_row{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}
.headerBack_backIconOuter{
    width: 2.4rem;
    height: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 3.6rem;
}
.headerBack_backIcon{
    width: 0.9rem;
}
.headerBack_closeIcon{
    width: 2.4rem;
}
.headerBackTitle_left{
    display: flex;
    align-items: center;
    width: 100%;
}
.headerBackTitle_backIconOuter{
    width: 2.4rem;
    height: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1.2rem;
}




/* modal */
/* moda_login */
.modal_LoginInner{
    padding: 0
}
.modal_loginModal{
    background-color: #000;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal_loginBody{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.modal_loginBodyContainer{
    height: 100%;
    /* max-height: 85.2rem; */
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.modal_loginBody_close{
    position: absolute;
    top: 2.1rem;
    right: 2.1rem;
    cursor: pointer;
}
.modal_loginBody_closeIcon{
    width: 3.6rem;
    padding: 0.2rem;
}
.modal_loginBody_section{
    width: 35.1rem;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.modal_loginBody_contantContainer{
    width: 100%;
    max-width: 35.1rem;
    height: 100%;
    max-height: 51rem;
    background-color: var(--login-box-bg);
    border-radius: 2rem;
    padding: 3.9rem 2rem 4.1rem;
    position: relative;
    overflow: hidden;
}
.modal_loginBody_contantContainerInner{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.modal_loginBody_contantLogo{
    width: 16.8rem;
    margin: 0 auto;
}
.modal_loginBody_contantText{
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.37) 0%, rgba(0, 0, 0, 0.07) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 3.8rem;
}
.modal_loginBody_contantTextInputMargin{
    margin-top: 1.9rem;
}
.modal_loginBody_contantTextInputMargin button:disabled, .modal_loginBody_contantTextInputMargin button[data-disabled] {
    background-color: transparent !important;
}
.modal_loginBottom{
    margin-top: 1.9rem;
    padding: 0 2rem;
    margin-bottom: 3rem;
}
.modal_loginBottom_text{
    text-align: center;
}
.modal_loginBottom_textRed{
    text-decoration-line: underline;
}

/* first time login modal */
.rs-picker-menu{
    z-index: 300 !important;
}
.modal_loginBodyContainer .rs-picker-toggle-wrapper{
    width: 100% !important;
    background-color: var(--textInput-bg) !important;
    height: 4.6rem !important;
    border-radius: 5rem !important;
    border: none !important;
}
.modal_loginBodyContainer .rs-picker-toggle{
    width: 100% !important;
    background-color: var(--textInput-bg) !important;
    height: 4.6rem !important;
    border-radius: 5rem !important;
    border: none !important;
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
    padding-left: 0rem !important;
    border: unset !important;
    box-shadow: unset !important;
}
.modal_loginBodyContainer .rs-picker-toggle-placeholder{
    padding-left: 2.2rem !important;
    color: #adb5bd !important;
    font-size: 1.4rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    line-height: 24.38.8rem;
}
.modal_loginBodyContainer .rs-picker-toggle-textbox, .modal_loginBodyContainer .rs-picker-toggle-value{
    padding-left: 2.2rem !important;
    color: #000000 !important;
    font-size: 1.4rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    line-height: 24.38.8rem;
    background-color: var(--textInput-bg) !important;
    border: unset !important;
}
.modal_loginBodyContainer .rs-picker-toggle-caret, .modal_loginBodyContainer .rs-picker-toggle-clean{
    top: 50% !important;
    right: 2rem !important;
    transform: translateY(-50%);
}

/* modal_video */
.modal_videoInner{
    padding: 0
}
.modal_videoModal{
    background-color: transparent;
    padding: 0;
}
.modal_videoModal_iframeContainer{
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    overflow: hidden;
}
.modal_videoModal_iframe{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0
}

/* modal_crop */
.modal_imageCropHeader{
    display: flex;
    justify-content: flex-end;
}
.modal_imageCropHeaderButton{
    width: 2.4rem;
    height: 2.4rem;
}
.modal_imageCropBottomList{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 2rem;
}
.modal_imageCropBottom{
    height: 3.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    /* min-width: 10rem; */
    border-radius: 10rem;
    width: 100%;
}
.modal_imageCropBottomCancel p{
    color: #E91C21;
}
.modal_imageCropBottomCancel:hover p{
    color: #C5181C;
}
.modal_imageCropBottomCancel:active p{
    color: #A01519;
}
.modal_imageCropBody{
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    margin-top: 1rem;
}
.modal_imageCropBodyLeft{
    flex: 1;
}
.modal_imageCropBodyRight{
    width: 10rem;
}
.modal_imageCropBodyLeftDragZone{
    width: 100%;
    /* background-color: #D9D9D9; */
    min-height: 20rem;
    border: 0.2rem dashed #B0B0B0;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.modal_imageCropBodyLeftDragZone_icon{
    width: 5rem;
    height: 5rem;
    color: #B0B0B0;
}
.modal_imageCropBodyLeftDragZone_text{
    color: #B0B0B0;
}
.modal_imageCropBodyRightPrevImage{
    width: 10rem;
    height: 10rem;
    margin-bottom: 1rem;
    background-color: #D9D9D9;
    border-radius: 50%;
}
.modal_imageCropBodyRightButton{
    border-radius: 5rem;
    width: 100%;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #99D8EA;
}
.modal_imageCropBodyRightButton p{
    color: #007DA5;
}

/* modal image */
.modal_imageInner{
    padding: 0;
    background-color: #000000e8;
}
.modal_imageModal{
    background-color: transparent;
    padding: 0;
}
.modal_imageHeader{
    height: 4.4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding-top: 2rem;
    z-index: 10;
}
.modal_imageHeader_closeIcon{
    width: 3.6rem;
    height: 3.6rem;
    padding: 0.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 11;
}
.modal_imageBackground{
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}
.modal_imageBackground .mySwiper{
    margin-top: 1rem;
}
.moda_image_mainImage{
    width: 100%;
    height: calc(100vh - 4.4rem - 19.5rem);
    position: relative;
    overflow: hidden;
    border-radius: 0.5rem;
    max-height: 108rem;
}
.moda_image_mainImageBackground{
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.moda_image_subImage{
    width: 100%;
    padding-top: 56.25%;
    position: relative;
    overflow: hidden;
    border-radius: 0.5rem;
}
.moda_image_subImageBackground{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.moda_image_subImageBackground_active{
    /* box-shadow: 0rem 0rem 2rem .2rem rgba(153,216,234,0.6);
    -webkit-box-shadow: 0rem 0rem 2rem .2rem rgba(153,216,234,0.6);
    -moz-box-shadow: 0rem 0rem 2rem .2rem rgba(153,216,234,0.6); */
    border: 0.2rem solid white;
    border-radius: 0.5rem;
    overflow: hidden;
}
.modal_imageBackground .mySwiper .swiper-slide{
    width: 15rem !important;
    /* padding-top: 2rem;
    padding-bottom: 2rem; */
}
/* .modal_imageBackground .mySwiper .swiper-wrapper{
    justify-content: center;
} */
.modal_vehicleRoot{
    position: absolute;
    z-index: 9;
}
.modal_vehicleModal{
    width: 100%;
    padding: 0;
    /* scroll-margin-top: 11.3rem; */
}
.modal_vehicle .mantine-Modal-modal {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.modal_vehicle .mantine-Modal-modal::-webkit-scrollbar {
    display: none;
}
.modal_vehicleModalStickyHeader{
    position: sticky;
    top: 0;
    /* padding-top: 11%; */
    background-color: white;
    z-index: 9;
}
.modal_vehicle_selectHeader{
    /* height: 6.4rem; */
    /* padding-top: 2.8rem; */
}
.modal_vehicle_selectHeaderInner{
    background-color: var(--color-red);
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 4.1rem;
}
/* modal vehicleModal model */
.modal_vehicleModal_modelBrands_imageOuter{
    width: 11.4rem;
    height: 11.4rem;
    position: relative;
    margin: 2.7rem auto 0;
}
.modal_vehicleModal_modelBrands_image{
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.modal_vehicleModal_modelList{
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start;
    gap: 1.1rem;
    margin-top: 2.8rem;
}
.modal_vehicleModal_modelItem{
    width: calc(50% - 0.55rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.3rem;
    border-radius: 2rem;
    background: #FFF;
    box-shadow: 0rem 0rem 1rem 0rem rgba(125, 201, 254, 0.20);
    padding: 1.7rem 1.8rem;
}
.modal_vehicleModal_modelItem_imageOuter{
    width: 13.3rem;
    height: 7rem;
    position: relative;
    /* padding-top: 52.22%; */
}
.modal_vehicleModal_modelItem_image{
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.modal_vehicleModal_modelItem_text{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    width: 100%;
    text-align: center;
}

/* modal vehicle variant */
.model_vehicleModal_variantTop{
    display: flex;
    align-items: center;
    gap: 2.6rem;
    justify-content: flex-start;
    margin-top: 1.4rem;
}
.modal_vehicleModal_variantTop_imageOuter{
    width: 16.3rem;
    height: 10.719rem;
    position: relative;
}
.modal_vehicleModal_variantTop_image{
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.model_vehicleModal_variantBottom{
    margin-top: 1.4rem;
}

/* image list */
.imageList{
    margin-top: 2.7rem;
    margin-bottom: 1.5rem;
}
.imageList_mainImage{
    width: 100%;
    padding-top: 56.25%;
    position: relative;
    overflow: hidden;
    border-radius: 0.5rem;
}
.imageList_mainImageBackground{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
}
.imageList_mainImageBackgroundLast{
    background-color: #000000b8;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
}
.imageList_subImageRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 0.4rem;
    gap: 0.4rem
}
.imageList_subImageColumn{
    width: 33.33%;
}

/* header */
.header{
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    align-items: center;
    padding-top: calc(11% + 1.6rem);
    padding-bottom: 1.6rem;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 9;
    background-color: #fff;
    width: 100%;
}
.header_row{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}
.header_logo{
    width: 13.4rem;
}
.header_search{
    flex: 1 1 0%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 2rem;
    height: 2.8rem;
    margin-top: 0.2rem;
}
.header_searchInner{
    width: 100%;
    height: 100%;
    background-color: rgba(112, 112, 112, 0.1);
    border-radius: 0.2rem;
    position: relative;
    padding-left: 2.8rem;
}
.header_searchInner_icon{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 2.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.header_searchInner_text{
    height: 100%;
}
.header_searchInner_text .mySwiper{
    height: 100%;
}
.header_searchInner_text_box{
    display: flex;
    align-items: center;
    height: 100%;
}
.header_searchInner_text_box_text{
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.header_iconList{
    display: flex;
    gap: 1.2rem;
    min-width: 6rem;
    justify-content: flex-end;
    height: 2.4rem;
}
.header_iconSingleList{
    display: flex;
    width: 2.4rem;
    margin-left: 1.2rem;
    justify-content: flex-end;
    height: 2.4rem;
}

/* bottom navigation */
.bottomNavigationMobile{
    padding-bottom: 0;
}
.bottomNavigation{
    /* position: sticky;
    bottom: 0;
    left: 0;
    right: 0; */
    background-color: var(--bottomNavigation-bg);
    box-shadow: 0rem -0.4rem 1.3rem 0rem rgba(153, 216, 234, 0.48);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 9;
    height: 5.5rem;
}
.bottomNavigation_homeButton{
    width: 6.5rem;
    height: 6.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, #ddd 0%, #AAA 100%);
    transform: translate(rem, -1.1rem);
}
.bottomNavigation_homeButton_active{
    background: linear-gradient(135deg, #FF4044 0%, #E91C21 100%);
}
.bottomNavigation_homeIcon{
    width: 3.5rem;
}
.bottomNavigation_button_column{
    display: flex;
    flex: 1;
    justify-content: center;
}
.bottomNavigation_button_outner{
    justify-content: center;
    display: flex;
    flex: 1;
    max-width: 12.5rem;
}
.bottomNavigation_button{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 12.5rem;
    cursor: pointer;
    height: 5.5rem;
}
.bottomNavigation_button_active{
    border-radius: 5rem;
    background-color: var(--bottomNavigation-button-active);
}
.bottomNavigation_buttonFlex{
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    justify-content: center;
    flex-direction: column;
}
.bottomNavigation_icon{
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.bottomNavigation_text{
    color: var(--bottomNavigation-inactive-color);
}
.bottomNavigation_text_active{
    color: var(--bottomNavigation-active-color);
}


/* vehicle Review Page component */
.vehicleReviewPage{
    padding-bottom: 2rem;
}
.vehicleReviewPage .vehicleUserReview_container{
    padding-top: 1.2rem;
}
.vehicleReviewPage .vehicleUserReview_row1{
    padding-top: 0.4rem;
}
.vehicleReviewPage_button{
    height: 5.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3rem;
    margin-top: 2.1rem;
}
.vehicleReviewPage .vehicleUserReview_commandItem_header{
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 0.7rem;
}
.vehicleReviewPage_commandItem_header_profileImageOuter{
    width: 4.6rem;
    height: 4.6rem;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
}
.vehicleReviewPage_commandItem_header_profileImage{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.vehicleReviewPage .vehicleUserReview_commandItem_description{
    padding-top: 1.4rem;
}
.vehicleReviewPage .vehicleUserReview_commandItem{
    border: unset;
    border-radius: 1.5rem;
    background: #FFF;
    box-shadow: 0rem 0rem 1.3rem 0rem rgba(153, 216, 234, 0.48);
}
.vehicleReviewPage .vehicleUserReview_commandList{
    padding-top: 3.3rem;
}
.vehicleReviewPage_category_button{
    height: 3.4rem;
    min-width: 8rem;
    padding-left: 1.4rem;
    padding-right: 1.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2rem;
    background-color: #E5F9FF;
}
.vehicleReviewPage_category_button p{
    color: #007DA5;
}
.vehicleReviewPage_category_button_active{
    background-color: #007DA5;
}
.vehicleReviewPage_category_button_active p{
    color: #FFF;
}
.vehicleReviewPage_category_list{
    padding-top: 2.1rem;
    width: 100%;
    display: flex;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.vehicleReviewPage_category_list::-webkit-scrollbar {
    display: none;
}
.vehicleReviewPage_category_wrap{
    display: flex;
    gap: 0.8rem;
    align-items: center;
}
.vehicleReviewPage .vehicleUserReview_commandList{
    gap: 1.1rem;
}

/* vehicle Write Review Page */
.vehicleWriteReviewPage_header{
    padding-top: 2.8rem;
}
.vehicleWriteReviewPage_headerVehicle{
    display: flex;
    align-items: center;
    height: 10.9rem;
    padding: 1.1rem .9rem;
    gap: 1.7rem;
    border-radius: 2rem;
    background: #FFF;
    box-shadow: 0rem 0rem 1rem 0rem rgba(125, 201, 254, 0.20);
}
.vehicleWriteReviewPage_headerVehicle_imageOuter{
    height: 7.693rem;
    width: 11.7rem;
}
.vehicleWriteReviewPage_rating{
    margin-top: 1.5rem;
    padding: 1.8rem 1.9rem;
    height: 6rem;
    display: flex;
    gap: 2.6rem;
    align-items: center;
    border-radius: 2rem;
    background: #FFF;
    box-shadow: 0rem 0rem 1rem 0rem rgba(125, 201, 254, 0.20);
}
.vehicleWriteReviewPage_rating_starList{
    display: flex;
    align-items: center;
}
.vehicleWriteReviewPage_rating_starIcon{
    width: 3.8rem;
    height: 3.8rem;
}
.vehicleWriteReviewPage_writeReviewContainer{
    padding-top: 3.1rem;
}
.vehicleWriteReviewPage_textareaContainer{
    position: relative;
    height: 20.5rem;
}
.vehicleWriteReviewPage_textarea_input{
    border-radius: 2rem;
    border: 0.1rem solid #E2E2E2;
}
.vehicleWriteReviewPage_textarea_totalNumBox{
    position: absolute;
    bottom: 3.6rem;
    right: 1.2rem;
}
.vehicleWriteReviewPage_submitButton{
    height: 5.2rem;
    border-radius: 3rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2.5rem;
    border: unset;
}




/* empty data */
.emptyData{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 8rem;
    width: 100%;
    gap: 1rem;
}
.emptyData_color_text{
    color: #7C7C7C
}

/* loading data */
.loadingData{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 8rem;
    width: 100%;
    gap: 1rem;
}
.loadingData_color_text{
    color: #7C7C7C
}

/* page */
/* home Banner */
.homeBanner{
    /* margin-top: 2rem; */
}
.homeBanner .swiper-horizontal>.swiper-scrollbar, .homeBanner .swiper-scrollbar.swiper-scrollbar-horizontal{
    width: 25%;
    left: 37.5%;
    bottom: 1.75rem;
    background: #484848;
}
.homeBanner .swiper-scrollbar-drag{
    background-color: #99D8EA;
}
.homeBanner .bannerSlideBackground {
    padding-top: 51.26%;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}
.homeBanner .bannerSlideLinearBackground{
    /* background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.90) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.90) 100%); */
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
}
.homeBanner .bannerSlideText {
    position: absolute;
    bottom: 2.7rem;
    left: 2.1rem;
    right: 2.1rem;
    height: 5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.homePage_rankingBoxOuter{
    padding-top: 2rem;
    padding-bottom: 3rem;
}
.homePage_rankingBox{
    width: 100%;
    border-radius: 2rem;
    background: #FFF;
    padding: 0 2rem 1.2rem;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0rem 0rem 1rem 0rem rgba(125, 201, 254, 0.31);
}
.homePage_rankingBox_titleRow{
    height: 7rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
.homePage_rankingBox_logo{
    background-image: url('/assets/images/logo.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 14rem;
    height: 1.6rem;
    z-index: 5;
}
.homePage_rankingBox_speedTest{
    background: linear-gradient(90deg, #E91C21 1.57%, rgba(233, 28, 33, 0.41) 89.63%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.homePage_rankingBox_test{
    background-image: url('/assets/images/ranking_speed_test2.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 17rem;
    height: 5.2rem;
    top: 2.7rem;
    left: 1.1rem;
    z-index: 4;
    position: absolute;
}
.homePage_rankingBox_cup{
    background-image: url('/assets/images/ranking_cup.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 10.6rem;
    height: 12.1rem;
    top: 0.8rem;
    right: 2.3rem;
    z-index: 3;
    position: absolute;
}
.homePage_rankingBox_content{
    gap: 0.8rem;
    display: flex;
    flex-direction: column;
    z-index: 5;
    position: relative;
    width: 100%;
}
.homePage_rankingBox_viewMoreBox{
    margin-top: 1.2rem;
}
.homePage_category_list{
    margin-top: 0.9rem;
    overflow-x: auto !important;
    width: 100%;
    display: flex;
}
.homePage_category_wrap{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex: 1;
}
.homePage_category_item{
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 6.5rem;
    flex: 1;
    gap: 0.4rem;
}
.homePage_category_imageBox{
    width: 3.6rem;
    height: auto;
}

.homePage_popularBoxOuter{
    width: 100%;
}
.homePage_popularBox{
    width: 100%;
    height: 23.8rem;
    border-radius: 2rem;
    position: relative;
    padding: 1rem 0 2.2rem;
}
.homePage_popularBox_shadow{
    position: absolute;
    box-shadow: 0rem 0rem 1rem 0rem rgba(125, 201, 254, 0.2);
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
    top: 61.5rem;
    bottom: 1.2rem;
    left: 2rem;
    right: 2rem;
}
.homePage_popularBox_shadowTab{
    box-shadow: 0rem 0rem 1rem 0rem rgb(254 125 125 / 20%);
    position: absolute;
    left: 0;
    right: 10%;
    top: 0;
    /* bottom: 0; */
    z-index: 2;
    border-top-left-radius: 2rem;
    height: 3.95rem;
}
.homePage_popularBox_shadowTab_modelActive{
    top: 0.45rem;
    right: 0%;
    bottom: 0;
    height: unset;
}
.homePage_popularBox_shadowTab_model_active{
    right: calc(39%);
}
.homePage_popularBox_tabList{
    display: flex;
    justify-content: space-between;
    /* height: 51.5rem;; */
    height: 7.15rem;
    overflow: hidden;
    position: relative;
    padding-top: 1.2rem;
}
.homePage_popularBox_tabItem{
    height: 39.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.homePage_popularBox_tabItemOuter_brand{
    width: 55%;
    margin-right: -3%;
    position: relative
}
.homePage_popularBox_tabItemOuter_model{
    width: 55%;
    margin-left: -3%;
    position: relative
}
.homePage_popularBox_tabItem_brand{
    clip-path: polygon(0 0, 100% 0%, 90% 100%, 0% 100%);
    z-index: 4;
    border-top-left-radius: 2rem;
    margin-top: 0.45rem;
    background-color: #FFF;
    position: relative;
    z-index: 3;
    /* padding-bottom: 2rem; */
    /* height: 100%; */
    height: 3.95rem;
}
.homePage_popularBox_tabItem_model{
    /* background: linear-gradient(115deg, #e65f64 11.53%, #e0b4b5 89.16%); */
    background-color: #E91C21;
    clip-path: polygon(0% 0, 100% 0%, 100% 100%, 10% 100%);
    margin-top: 0.45rem;
    z-index: 4;
    border-top-right-radius: 2rem;
    padding-bottom: 2rem;
    height: 100%;
}
.homePage_popularBox_tabItem_brand p, .homePage_popularBox_tabItem_model p{
    margin-top: -0.9rem
}
.homePage_popularBox_tabList .active{
    z-index: 5;
}
.homePage_popularBox_tabList .active .homePage_popularBox_tabItem_brand{
    margin-top: unset;
    clip-path: polygon(0 0, calc(100% - 2rem) 0%, 100% 100%, 0% 100%);
}
.homePage_popularBox_tabList .active .homePage_popularBox_tabItem_model{
    margin-top: unset;
    clip-path: polygon(2rem 0, 100% 0%, 100% 100%, 0% 100%);
}
.homePage_popularBox_tabList .active p{
    margin-top: 0
}
.homePage_popularBox_content{
    background-color: #FFF;
    margin-top: -2.1rem;
    width: 100%;
    height: 16.4rem;
    z-index: 5;
    position: relative;
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
}
.homePage_popularBox_content_brand{
    background-color: #FFF;
    width: 100%;
    height: 16.6rem;
    z-index: 5;
    position: relative;
    display: grid;
    grid-template-columns: repeat(5, auto);
    justify-items: center;
    align-items: center;
    padding: 0.5rem;
    border-top-right-radius: 2rem;
    box-shadow: 0rem 0rem 1rem 0rem rgb(254 125 125 / 20%);
    clip-path: inset(0px -1rem -1rem -1rem);
}
.homePage_popularBox_content_model{
    /* background: linear-gradient(115deg, #99D8EA 11.53%, #CEF4FF 89.16%); */
    /* background: linear-gradient(115deg, #E91C21 11.53%, #DEC6C7 89.16%); */
    background-color: #E91C21;
    width: 100%;
    height: 16.6rem;
    z-index: 5;
    position: relative;
    border-top-left-radius: 2rem;
    position: relative;
    box-shadow: 0rem 0rem 1rem 0rem rgb(254 125 125 / 20%);
    clip-path: inset(0px -1rem -1rem -1rem);
}
.homePage_popularBox_content_model_transparent{
    position: absolute;
    top: 0.3rem;
    bottom: 0.3rem;
    left: 0.3rem;
    right: 0.3rem;
    border-radius: 1.6rem 1.8rem;
    /* border-bottom-left-radius: 2rem 1.5rem;
    border-bottom-right-radius: 2rem 1.5rem;
    border-top-left-radius: 2rem 1.5rem;
    border-top-right-radius: 1.5rem 2rem; */
    background-color: #FFFFFF;
    backdrop-filter: blur(2.7rem);
}
.homePage_popularBox_brand_item{
    width: 5.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.homePage_popularBox_brand_item_imageOuter{
    width: 4.5rem;
    height: 4.5rem;
    position: relative;
}
.homePage_popularBox_brand_item_imageOuter_moreIcon{
    width: 3.5rem;
}
.homePage_popularBox_brand_item_image{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
}
.homePage_popularBox_brand_item_text{
    flex: 1 1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
    text-align: center;
    position: relative;
    min-height: 1.75rem;
    min-width: 4.5rem;
}
.homePage_popularBox_content_model_transparent_vehicleList{
    display: flex;
    overflow-x: auto !important;
    width: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.homePage_popularBox_content_model_transparent_vehicleList::-webkit-scrollbar {
    display: none;
}
.homePage_popularBox_content_model_transparent_vehicleWrap{
    display: flex;
    align-items: flex-end;
    padding-top: 1.65rem;
    padding-bottom: 1.65rem;
    gap: 0.5rem;
}
.homePage_popularBox_content_model_transparent_vehicleItem{
    width: 12.6rem;
}
.homePage_popularBox_content_model_transparent_vehicleItem_image{
    width: 12.6rem;
    height: 8.286rem;
    margin-bottom: 0.5rem;
}
.homePage_recommandedVehicle{
    width: 100%;
    margin: 1.5rem 0rem;
}
.homePage_recommandedVehicle_title{
    width: 100%;
    height: 2.5rem;
    display: flex;
    align-items: center;
}
.homePage_recommandedVehicle_content{
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}
.homePage_recommandedVehicle_list{
    display: flex;
    overflow-x: scroll !important;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.homePage_recommandedVehicle_list::-webkit-scrollbar {
    display: none;
    width: 100%;
}
.homePage_recommandedVehicle_wrap{
    display: flex;
    gap: 1.9rem;
}
.homePage_recommandedVehicle_item{
    width: 12.6rem;
}
.homePage_recommandedVehicle_item_image{
    width: 12.6rem;
    height: 8.286rem;
}
.homePage_placementMargin{
    padding-top: 1.3rem;
    padding-bottom: 2.5rem;
}

/* common */
.commonCategory_list{
    display: flex;
    /* gap: 2rem; */
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    overflow: auto !important;
    margin: 1.1rem 0;
    -ms-overflow-style: none;
    scrollbar-width: none;
    gap: 1rem;
}

/* news */
.newsCategory_list{
    display: flex;
    /* gap: 2rem; */
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    overflow: auto !important;
    margin: 2.1rem 0;
    -ms-overflow-style: none;
    scrollbar-width: none;
    gap: 1rem;
}
.newsCategory_list::-webkit-scrollbar {
    display: none;
}
.newsCategory_text {
    color: var(--newsCategory-inactive-color);
    font-weight: 400;
    white-space: nowrap;
    padding: 0 1rem;
    cursor: pointer;
    text-align: center;
}
.newsCategory_textActive {
    color: var(--newsCategory-active-color);
    font-weight: 700;
    white-space: nowrap;
}
.newsCategory_item{
    min-height: 2.438rem;
    /* min-width: 10rem; */
    position: relative;
}
.newsCategory_itemSkeleton{
    min-height: 2.438rem;
    min-width: 10rem;
    position: relative;
}
.newsCategory_itemFull{
    flex: 1;
}
.newsCategorySub_list{
    display: flex;
    gap: 1rem;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    overflow: auto !important;
    margin: 0 0 2.1rem;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.newsCategorySub_list::-webkit-scrollbar {
    display: none;
}
.newsCategorySub{
    /* min-width: 8rem; */
    height: 3.4rem;
    padding: 0 1.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2rem;
    background-color: var(--newsSubCategory-inactive-bg);
    color: var(--newsSubCategory-inactive-color);
    cursor: pointer;
}
.newsCategorySub_active{
    background-color: var(--newsSubCategory-active-bg);
    color: var(--newsSubCategory-active-color)
}
.newsCategorySub_active .newsCategorySub_text{
    color: var(--newsSubCategory-active-color)
}
.newsCategorySub_text{
    white-space: nowrap;
    min-width: 5.2rem;
    text-align: center;
}
.newsCategory_image{
    height: 0.5rem;
}

.newsCategory_active{
    height: 0.5rem;
    background: red;
    clip-path: polygon(0 0, 0% 100%, 100% 50%);
}
.newsItemsVideoTop{
    padding-top: 2rem;
}
.news_video {
    padding-bottom: 2.6rem;
}
.news_video_box{
    border-radius: 1.5rem;
    background: #FFF;
    box-shadow: 0rem 0rem 1.3rem 0rem rgba(153, 216, 234, 0.48);
    overflow: hidden;
}
.news_video_imageSkeleton{
    padding-top: 51.26%;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    position: relative
}
.news_video_image{
    padding-top: 51.26%;
    position: relative;
    overflow: hidden;
    background-color: var(--color-lightGrey);
}
.news_video_image_img{
    position: absolute; 
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%);
    width: 100%;
}
.news_video_content{
    padding: 1rem 1rem 1.3rem 1rem
}
.news_video_text{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.news_video_bottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.8rem;
}

.news_video_date{
    color: var(--date-color);
}
.news_itemBox{
    padding: 1.6rem 0rem 1.7rem 0rem;
    cursor: pointer;
}
.news_itemBox_text{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.news_itemBox_imageList{
    display: flex;
    gap: 0.5rem;
    margin-top: 1rem;
}
.news_itemBox_imageSkeleton{
    flex: 1;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 1rem;
    padding-top: 18.76%;
    position: relative;
}
.news_itemBox_image{
    flex: 1;
    border-radius: 1rem;
    padding-top: 18.76%;
    position: relative;
    overflow: hidden;
    background-color: var(--color-lightGrey);
}
.news_itemBox_image_img{
    position: absolute; 
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%);
    width: 100%;
}
.news_itemBox_bottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.8rem;
}
.news_itemBox_tag{
    display: flex;
    gap: 0.6rem;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
}
.news_latestNews_date{
    align-self: center;
    justify-self: flex-start;
    display: flex;
    flex: 1 1;
    justify-content: flex-end;
    min-width: 6.8rem;
    white-space: nowrap;
}
.news_itemBox_view_and_date{
    display: flex;
    min-width: 15.5rem;
    align-items: center;
    flex: 1;
    gap: 0.8rem;
}

/* latest news */
.latestNews{
    margin: 1.6rem 0 2rem;
    cursor: pointer;
}
.latestNews_title{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.latestNews_imageSkeleton{
    margin-top: 1.2rem;
    padding-top: 51.26%;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-color: var(--color-lightGrey);
    border-radius: 1.5rem;
    position: relative;
}
.latestNews_image{
    margin-top: 1.2rem;
    padding-top: 51.26%;
    /* background-size: 100%;
    background-position: center;
    background-repeat: no-repeat; */
    background-color: var(--color-lightGrey);
    border-radius: 1.5rem;
    position: relative;
    overflow: hidden;
}
.latestNews_image_img{
    position: absolute; 
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%);
    width: 100%;
}
.latestNews_date{
    margin-top: 0.4rem;
    color: var(--date-color)
}

/* hottest */
/* hottestNews */
.hottestNews{
    width: 100%;
    margin: 1.5rem 0rem;
    /* background: var(--hottest-linear); */
    position: relative;
}
.hottestNewsTitle{
    padding: 0rem 2rem;
    width: 100%;
    height: 2.5rem;
    display: flex;
    align-items: center;
}
.hottestNewsList{
    padding: 1.5rem 0 0 0;
    display: flex;
    width: 100%;
    overflow: auto !important;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.hottestNewsListIniline {
    display: flex;
    padding: 0 2.1rem;
    gap: 0.9rem;
}
.hottestNewsItems{
    max-width: 33.2rem;
    height: 12.3rem;
    background-color: #FFFFFF;
    border-radius: 1rem;
    padding: 0.9rem 1rem .9rem 1rem;
    cursor: pointer;
    border: 0.1rem solid #0000001c;
}
.hottestNewsItemsInner{
    display: flex;
    align-items: center;
    gap: 1.2rem
}
.hottestNewsItemsLeft{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 10rem;
}
.hottestNewsItemsLeftTitle{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.hottestNewsItemsLeftDate{
    text-align: right;
    color: #ABABAB;
}
.hottestNewsItems_image{
    width: 10.4rem;
    height: 10.4rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 1.1rem;
}

/* hottestVideo */
.hottestVideo{
    width: 100%;
    margin: 1.5rem 0rem;
    /* background: var(--hottest-linear); */
    position: relative;
}
.hottestVideoTitle{
    padding: 0rem 2rem;
    width: 100%;
    height: 2.5rem;
    display: flex;
    align-items: center;
}
.hottestVideoList{
    padding: 1.5rem 0 0 0;
    display: flex;
    width: 100%;
    overflow: auto !important;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.hottestVideoList::-webkit-scrollbar {
    display: none;
}
.hottestVideoListIniline {
    display: flex;
    padding: 0 2rem;
    gap: 0.9rem;
}
.hottestVideoItem{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 15rem;
    height: 16.6rem;
}
.hottestVideoItem_video{
    padding-top: 62.66%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 1rem;
    position: relative;
}
.hottestVideoItem_text{
    margin-top: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 3.6rem;
    width: 100%;
}
.hottestVideoItem_view{
    margin-top: 0.6rem;
}

/* popularCarBrands */
.popularCarBrands{
    width: 100%;
    min-height: 15.4rem;
    background: #FFF;
    position: relative;
    margin-top: 1.5rem;
}
.popularCarBrands_white{
    background: #FFF;
}
.popularCarBrands_title{
    position: absolute;
    background: linear-gradient(90deg, #FF383D 2.27%, #FF9559 108.33%), linear-gradient(90deg, #FF9559 2.27%, #FBE599 108.33%);
    top: -0.4rem;
    left: 0rem;
    right: 0rem;
    padding: 0 3.1rem 0 2.1rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 3.3rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}
.popularCarBrandsList{
    padding-top: 4.7rem;
    display: flex;
    width: 100%;
    overflow: auto !important;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.popularCarBrandsList::-webkit-scrollbar {
    display: none;
}
.popularCarBrandsListIniline {
    display: flex;
    padding: 0 2.1rem;
    gap: 2rem;
    flex-direction: row;
    margin-bottom: 1.5rem;
}
.popularCarBrandsItem{
    width: 6.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.popularCarBrandsItem_imageOuter{
    width: 5.5rem;
    height: 5.5rem;
    position: relative;
}
.popularCarBrandsItem_image{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
}
.popularCarBrandsItem_text{
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
    text-align: center;
}

/* popularCarBrands2 */
.popularCarBrands2{
    padding-bottom: 1.9rem;
}
.popularCarBrands2_list{
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    grid-template-rows: auto auto;
    grid-row-gap: 1.5rem;
    padding-top: 5rem;
    justify-items: center;
}
.popularCarBrands2_viewmore{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 1.7rem;
}

/* recommended Cars  */
.recommendedCars{
    width: 100%;
    height: 19rem;
    background: #FFF;
    position: relative;
}
.recommendedCars_title{
    position: absolute;
    background: linear-gradient(102deg, #7CC8FF 0%, #99D8EA 100%), linear-gradient(90deg, #FF383D 2.1%, #FF9559 100%);
    top: -0.4rem;
    left: 0rem;
    right: 0rem;
    padding: 0 3.1rem 0 2.1rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 3.3rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}
.recommendedCarsList{
    padding-top: 7.4rem;
    display: flex;
    width: 100%;
    overflow: auto !important;
    -ms-overflow-style: none;
    scrollbar-width: none;
    height: 100%;
}
.recommendedCarsList::-webkit-scrollbar {
    display: none;
}
.recommendedCarsListIniline {
    display: flex;
    padding: 0 2.1rem;
    gap: 0.8rem;
}
.recommendedCarsItem{
    width: 15.2rem;
    height: 11.3rem;
    background-color: #FFFFFF;
    border-radius: 2rem;
    position: relative;
    box-shadow: 0rem 0rem 1rem 0rem rgba(125, 201, 254, 0.20);
    padding-top: 3.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
.recommendedCarsItem p{
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.recommendedCarsItem_imagePosition{
    position: absolute;
    left: 50%;
    bottom: 7.5rem;
    display: flex;
    justify-content: center;
    width: 11.1rem;
    height: 7.3rem;
    transform: translateX(-50%);
}

/* image component */
/* image vehicle */
.vehicleImageOuter{
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 0.5rem;
}

.vehicleImage{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.vehicleImage_tag{
    position: absolute;
    top: 0.4rem;
    left: 0.4rem;
}

/* page */

/* news */
/* news detail */
.newsDetail_title{
    margin-top: 1.2rem;
}
.newsDetail_titleText{
    line-height: 3.1356rem;
}
.newsDetail_coverImageOuter{
    position: relative;
    width: 100%;
    padding-top: 52.42%;
}
.newsDetail_coverImage{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.newsDetail_author{
    display: flex;
    align-items: center;
    gap: 0.9rem;
    margin-top: 1rem;
}
.newsDetail_authorImage{
    width: 3.6rem;
    height: 3.6rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
}
.newsDetail_authorText{
    color: #787878;
}
.newsDetail_authorDate{
    color: #ABABAB;
}
.newsDetail_media{
    margin-top: 1.7rem;
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
}
.newsDetail_content{
    margin-top: 2.1rem;
    padding-left: 2rem;
    padding-right: 2rem;
}
.newsDetail_content p+p{
    margin-top: 0.8rem;
}
.newsDetail_content iframe{
    width: 100%;
}
.newsDetail_shareMedia{
    margin-top: 1.7rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
}
.newsDetail_mediaList{
    display: flex;
    margin-top: 0.5rem;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}
.newsDetail_related{
    margin-top: 2.7rem;
}
.newsDetail_relatedModel{
    margin-top: 2.7rem;
}
.newsDetail_relatedModel_scrollContainer{
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    width: 100%;
    display: flex;
    padding-bottom: 1rem
}
.newsDetail_relatedModel_scrollContainer::-webkit-scrollbar {
    display: none;
}
.newsDetail_relatedModel_wrap{
    display: flex;
    gap: 0.8rem;
    align-items: center;
    width: 100%;
}
.newsDetail_relatedModel_box{
    border-radius: 1.5rem;
    background: #FFF;
    box-shadow: 0rem 0rem 1.3rem 0rem rgba(153, 216, 234, 0.30);
    margin-top: 3.7rem;
    min-width: 28rem;
    max-width: 32rem;
    width: calc(100vw - 4rem);
}
.newsDetail_relatedModel_header{
    padding-left: 16.6rem;
    position: relative;
    padding-top: 1.4rem;
    padding-bottom: 2.5rem;
}
.newsDetail_relatedModel_header_image{
    width: 16.4rem;
    height: 10.7rem;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-1rem, -25%);
}
.newsDetail_relatedModel_header_text{
    height: 4.8rem;
    padding-right: 1rem;
}
.newsDetail_relatedModel_header_text p{
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.newsDetail_relatedModel_infoContainer{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    gap: 1.8rem;
    padding-bottom: 1.8rem;
    padding-top: 0.8rem;
    padding-left: 1rem;
    padding-right: 1rem;
}
.newsDetail_relatedModel_infoBox{
    width: 12rem;
}
.newsDetail_relatedModel_bottom{
    height: 5.3rem;
    padding-left: 1.3rem;
    padding-right: 1.3rem
}
.newsDetail_relatedModel_bottomInner{
    border-top: 0.1rem solid #F0F0F0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.newsDetail_video_youtube{
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    overflow: hidden;
}
.newsDetail_video_tiktok{
    position: relative;
    width: 100%;
    height: 575px;
    overflow: hidden;
    margin-bottom: 2.5rem;
}

/* author detail */
.authorBackground{
    width: 100%;
    height: 55.5rem;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -40.36%);
    background-size: 100% 55.5rem;
    background-image: url('/assets/images/author_bg.png');
    background-repeat: no-repeat;
    background-position: top,center;
    background-color: lightgray;
    opacity: 0.5;
}
.authorBackground_inner{
    background: linear-gradient(360deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0) 30%);
    width: 100%;
    height: 100%;
}
.authorContainer{
    z-index: 2;
    position: relative;
}
.authorHeader{
    width: 100%;
    min-height: 16.6rem;
    position: relative;
    padding-top: 2rem;
    margin-top: 2.1rem;
}
.authorHeader_image{
    /* background-image: url(/assets/images/example/profile_sample.svg); */
    width: 7.8rem;
    height: 7.8rem;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: absolute;
    left: 1.4rem;
    top: 0;
    z-index: 3;
}
.authorHeader_description{
    width: 100%;
    min-height: 14.6rem;
    position: relative;
}
.authorHeader_descriptionLeftBg{
    background-image: url(/assets/images/author_description_bg.svg);
    background-size: cover;
    background-position: bottom center;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    width: 12rem;
    top: 0;
    height: 8rem;
}
.authorHeader_descriptionContent{
    z-index: 2;
    position: relative;
    clip-path: polygon(10.5rem 0, 100% 0, 100% 100%, 0 100%, 0 6rem, 8.9rem 6rem);
    background-color: white;
    min-height: 13rem;
    border-radius: 0 1.5rem 1.5rem 1.5rem;
}
.authorHeader_descriptionTitle{
    padding-left: 11.1rem;
    padding-top: 2.7rem;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.authorHeader_descriptionText{
    padding: 2.3rem 1.1rem 1rem 1.4rem;
    line-height: 2rem;
    color: #767676;
}
.authorBody{
    margin-top: 3.6rem;
}


/* profile */
.profileBackground{
    width: 100%;
    height: 133%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -32.75%);
    background-size: 100% 133%;
    background-image: url('/assets/images/profile_bg.png');
    background-repeat: no-repeat;
    background-position: top,center;
    background-color: lightgray;
    opacity: 0.5;
    pointer-events: none;
}
.profileBackground_inner{
    background: linear-gradient(360deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 30%);
    width: 100%;
    height: 100%;
}
.profile_row{
    align-items: flex-start;
    gap: 1.4rem;
    padding-top: 1rem;
    margin-bottom: 1.6rem;
    position: relative;
    z-index: 2;
    display: flex;
}
.profile_image{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    height: 7.1rem;
    width: 7.1rem;
    overflow: hidden;
    position: relative;
    /* background-color: #DBDBDB; */
}
.profile_image_img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.profile_badge{
    height: 2.2rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    border-radius: 4rem;
    border: 0.2rem dashed #7FCADA;
    background-color: #B4DFEB;
}
.profile_funcButton_row{
    display: flex;
    gap: 0.8rem
}
.profile_funcButton_button{
    width: 2.6rem;
    height: 2.6rem;
    padding: 0.4rem
}
.profile_myAccount_icon{
    width: 4.8rem;
    height: 4.8rem;
}
.profile_myAccount_icon_row{
    margin-top: 2rem;
    display: flex;
    gap: 2.6rem;
}
.profile_myAccount_icon_box{
    display: flex;
    align-items: center;
    width: fit-content;
    flex-direction: column;
}
.profile_myAccount_outer{
    margin-top: 1.6rem;
    margin-bottom: 1rem
}
.profile_myAccount{
    padding: 1.3rem 1.7rem 1.6rem ;
    border-radius: 2rem;
    width: 100%;
    background-color: #FFF;
    box-shadow: 0rem 0rem 2rem 0rem rgba(153, 216, 234, 0.30);
    z-index: 2;
    position: relative;
}
.profile_addVehicle_boxOuter{
    position: relative;
    height: 20rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.profile_addVehicle_boxOuter_click{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 12rem;
    left: 0;
    z-index: 4;
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
}
.profile_addVehicle_boxOuter_click2{
    position: absolute;
    top: 4rem;
    bottom: 0;
    right: -12rem;
    left: 100%;
    z-index: 4;
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
}
.profile_addVehicle_boxButton_add{
    display: flex;
    position: absolute;
    top: 0.7rem;
    right: 0.7rem;
    width: 9rem;
    height: 4rem;
    background-color: #0094FF;
    border-top-right-radius: 2rem;
    z-index: 2;
    justify-content: center;
    align-items: center;
    padding-left: 3rem;
    padding-bottom: 0.6rem;
}
.profile_addVehicle_boxButton_list{
    display: flex;
    position: absolute;
    top: 0.7rem;
    right: 0.7rem;
    width: 12rem;
    height: 4rem;
    background-color: #0094FF;
    border-top-right-radius: 2rem;
    z-index: 2;
    justify-content: center;
    align-items: center;
    padding-left: 2rem;
    padding-bottom: 0.6rem;
}
.profile_addVehicle_box{
    display:inline-block;
    filter:url(#round);
    width: 100%;
    position: relative;
    z-index: 4;
    pointer-events: none;
    overflow: hidden;
    border-top-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
    border-bottom-left-radius: 2rem;
}
.profile_addVehicle_box_inner {
    display:block;
    height: 20rem;
    background: 
        /* linear-gradient(318deg, rgba(199,248,255,0.7) 0%, rgba(255,255,255,0) 100%), */
        linear-gradient(348deg, rgba(199,248,255,1) 30%, rgba(255,255,255,0) 70%),
        linear-gradient(70deg, rgba(255,255,255,0) 35%, rgba(255,255,255,0.2) 35% 70%, rgba(255,255,255,0) 70%),
        linear-gradient(70deg, rgba(255,255,255,0) 50%, rgba(255,255,255,0.2) 50% 100%, rgba(255,255,255,0) 100%),
        /* linear-gradient(108deg, rgba(255,255,2550.3) 0%, rgba(255,255,2550.3) 0%, rgba(255, 255, 255, 0.00) 35%), */
        linear-gradient(108deg, #b0d6fa 0%, transparent 35%),
        linear-gradient(180deg, #b0d6fa 1%, #C7F8FF 100%),
        linear-gradient(180deg, #EEFBFF 0%, #FFF 78.32%);
    clip-path: polygon( 
        0% 0%,
        calc(100% - 12rem) 0%,
        calc(100% - 10rem) 4rem,
        100% 4rem,
        100% 100%,
        0% 100%
    );
    overflow: hidden;
}
.profile_addVehicle_box_title{
    position: absolute;
    top: 1.4rem;
    left: 2rem;
    z-index: 7;
}
.profile_addVehicle_box_text{
    position: absolute;
    top: 5rem;
    left: 2rem;
    z-index: 7;
}
.profile_addVehicle_boxBottom{
    position: absolute;
    background-color: white;
    bottom: 0;
    left: 0;
    right: 0;
    height: 4.5rem;
    z-index: 6;
    display: flex;
    align-items: center;
    gap: 0.8rem;
    padding-left: 2rem;
    padding-right: 2rem;
    justify-content: space-between;
}
.profile_addVehicle_boxBottom_item{
    flex: 1;
    display: flex;
    align-items: center;
    gap: 0.4rem;
    justify-content: center;
}
.profile_addVehicle_boxBottom_icon{
    width: 2rem;
    height: 2rem;
}
.profile_addVehicle_vehicleImage_position{
    width: 28rem;
    position: absolute;
    bottom: 4.5rem;
    z-index: 6;
    right: -5rem;

}
.profile_addVehicle_boxGlass{
    background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
    opacity: 0.3;
}
.profile_addVehicle_haveVehicle_box{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
}
.profile_addVehicle_haveVehicle_center{
    flex: 1;
    display: flex;
    gap: 1.2rem;
}
.profile_addVehicle_haveVehicle_center_image{
    width: 14rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.profile_addVehicle_haveVehicle_center_textRow{
    flex: 1
}
.profile_addVehicle_haveVehicle_center_textBox{
    flex: 1
}
.profile_addVehicle_haveVehicle_center_text{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.profile_addVehicle_haveVehicle_bottom{
    display: flex;
    align-items: flex-start;
    height: 7.9rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.profile_dailyCheckIn_outer{
    margin-top: 1rem;
    margin-bottom: 1rem
}
.profile_dailyCheckIn{
    padding: 1.3rem 1.7rem 1.6rem ;
    border-radius: 2rem;
    width: 100%;
    background-color: #FFF;
    box-shadow: 0rem 0rem 2rem 0rem rgba(153, 216, 234, 0.30);
    z-index: 2;
    position: relative;
}
.profile_dailyCheckIn_item_row{
    display: flex;
    gap: 0.4rem;
    align-items: flex-end;
    margin-top: 1rem;
    min-height: 5.7rem;
}
.profile_dailyCheckIn_item{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.2rem;
}
.profile_dailyCheckIn_item_icon{
    width: 4rem;
    height: 4rem;
    position: relative;
}
.profile_dailyCheckIn_item_icon_amount{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.profile_dailyCheckIn_item_checkedIn{
    background-color: var(--color-blue);
    position: absolute;
    top: 20%;
    bottom: 20%;
    left: 20%;
    right: 20%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.profile_dailyCheckIn_item_checkedIn_icon{
    width: 1.6rem;
    height: 1.6rem;
}
 

.emptyProfile_imageOutler{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.emptyProfile_image{
    /* background: linear-gradient(135deg, #FF4044 0%, #E91C21 100%);
    padding: 20%; */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.profile_imageTextBox{
    display: grid;
    flex: 1;
}
.profile_imageText{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
}

.profile_edit{
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 0.6rem;
    color: #7A7A7A;
}
.profile_editIcon{
    width: 1.6rem;
}

.profile_carTools_outer{
    margin-top: 1rem;
    margin-bottom: 3rem
}
.profile_carTools{
    padding: 1.3rem 1.7rem 1.6rem ;
    border-radius: 2rem;
    width: 100%;
    height: 17.4rem;
    background-color: #FFF;
    box-shadow: 0rem 0rem 2rem 0rem rgba(153, 216, 234, 0.30);
    z-index: 2;
    position: relative;
}
.profile_carTools_list{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 2.5rem;
}
.profile_carTools_item{
    display:flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}
.profile_carTools_text{
    line-height: normal;
    text-align: center;
    margin-top: 1rem;
}
.profile_carTools_icon{
    width: 4.3rem;
}
.profile_myCenter_item{
    margin-top: 1.6rem;
    padding: 1.2rem;
    border-radius: 1.6rem;
    box-shadow: 0rem 0rem 1.3rem 0rem rgba(153, 216, 234, 0.48);
    position: relative;
    z-index: 1;
    background-color: white;
}
.profile_myCenter_itemRow{
    display: flex;
    align-items: center;
    cursor: pointer;
}
.profile_myCenter_itemTitle{
    flex: 1
}
.profile_myCenter_itemRightText{
    color: #C0C0C0
}
.profile_myCenter_itemCollapseText{
    color: #7C7C7C;
    display: flex;
    flex: 1;
    /* margin-left: 4.1rem; */
}
.profile_myCenter_itemIcon{
    width: 2.8rem;
    margin-right: 1.3rem;
}
.profile_myCenter_itemLogoutIcon{
    display: flex;
    width: 2.8rem;
    height: 2.8rem;
    padding: 0.2333rem 0.2333rem 0.2333rem 0.35rem;
    justify-content: center;
    align-items: center;
    margin-right: 1.3rem;
}
.profile_myCenter_item_sub{
    margin-top: 1.6rem;
}

/* profile edit */
.editPage_button{
    height: 4.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10rem;
    width: 100%;
    border: transparent;
}
.editPage_buttonOuter{
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.editPage_header{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 2.6rem;
}
.editPage_profileImage{
    width: 9.8rem;
    height: 9.8rem;
    background-color: #D9D9D9;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.editPage_profileText{
    margin-top: 0.8rem;
}
.editPage_contentAboutMe{
    margin-top: 3.3rem;
}
.editPage_contentAboutMe_textInputBox{
    margin-top: 0.8rem;
}
.editPage_contentAboutMe_textInput{
    height: 4.5rem;
    border: transparent;
    background-color: #F1F1F1;
    border-radius: 5rem;
    padding-left: 2.2rem;
    padding-right: 2.2rem;
}
.editProfileContainer{
    display: flex;
    flex-direction: column;
}
.editPageForm{
    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/* vehicle */
.vehicle_comingSoon{
    height: calc(100% - 10rem);
    display: flex;
    align-items: center;
    justify-content: center;
}
.vehiclePage_vehicle_brandlistMargin{
    margin-top: 3.4rem;
}
.vehiclePage_lookCar_body{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1.4rem;
    gap: 0.8rem;
}
.vehiclePage_lookCar_body2{
    margin-top: 0.8rem;
}
.vehiclePage_lookCar_titleRow{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.4rem;
}
.vehiclePage_lookCar_goFilterIcon{
    width: 3rem;
    height: 3rem;
    position: relative;
}
.vehiclePage_lookCar_button{
    padding: 2% 3%;
    /* height: 4.2rem; */
    display: flex;
    background-color: var(--color-lightGrey);
    align-items: center;
    justify-content: center;
    border-radius: 2rem;
    /* font-size: 1.37vh; */
    flex: 1;
    gap: 0.4rem;
}
.vehiclePage_lookCar_button_text{
    font-size: 1.4rem;
    line-height: normal;
}

/* vehicle brand list */
.vehicle_brandlistContainer{
    position: relative;
}
.vehicle_brandlist_sideBarOuter{
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
    right: 0rem;
    top: 50%;
    transform: translateY(calc(-50% + 5.7rem));
}
.vehicle_brandlist_sideBar{
    /* height: 40rem; */
    /* overflow: auto; */
    /* padding-right: 2rem; */
    max-height: calc(100vh - 25rem);
    overflow: auto;
    /* padding-right: 2rem; */
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-top: 2rem;
    padding-bottom: 2rem;
    position: relative;
    margin-right: 1.5rem;
    background: white;
    border-radius: 5rem;
}
.vehicle_brandlist_sideBar::-webkit-scrollbar {
    display: none;
}
.vehicle_brandlist_sideBarVisible{
    position: absolute;
    left: 0;
    right: 0;
    height: 3rem;
    z-index: 11;
    pointer-events: none;
}
.vehicle_brandlist_sideBarVisibleTop{
    background: linear-gradient(180deg, #FFF 20%, rgb(255 255 255 / 0%) 80%);
    top: -0.5rem;
}
.vehicle_brandlist_sideBarVisibleBottom{
    background: linear-gradient(0deg, #FFF 20%, rgb(255 255 255 / 0%) 80%);
    bottom: -0.5rem;
}
.vehicle_brandlist_titlebox{
    background: var(--vehicleBrandTitle-linear);
    height: 3.7rem;
    display: flex;
    align-items: center;
}
.vehicle_brandlist_rowBox{
    height: 5.6rem;
    border-bottom: 0.1rem solid #EEE;
    display: flex;
    align-items: center;
    gap: 1rem;
}
.vehicle_brandlist_iconOutner{
    width: 4rem;
    height: 4rem;
    position: relative
}
.vehicle_brandlist_icon{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

/* vehicle brands page */
.vehicleBrands_header{
    width: 100%;
    height: 16rem;
    background: var(--vehicleBrands-linear);
    /* margin-top: 1.6rem; */
    padding-top: 2rem;
}
.vehicleBrands_header_inner{
    display: flex;
    align-items: center;
    gap: 2rem;
}
.vehicleBrands_header_iconBox{
    width: 8rem;
    height: 8rem;
    position: relative
}
.vehicleBrands_header_icon{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

/* vehicle Model page */
.vehicleModelContainer, .vehicleVariantContainer, .profileVehicleContainer{
    position: relative;
    background: var(--vehicleModel-linear);
    background-size: 100% 42.8rem;
    background-repeat: no-repeat;
    background-position: top;
}
.vehicleModel_vehicleHeader{
    position: relative;
}
.vehicleModel_divideLine, .profileVehicle_divideLine{
    width: 100%;
    height: 0.8rem;
    background-color: #F7F7F7;
}
.vehicleModel_buttonRow{
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
    position: absolute;
    bottom: 4rem;
    width: 100%;
}
.vehicleModel_vehicleHeaderImageOuter{
    width: 100%;
    /* height: 15.564rem; */
    margin: 0 auto;
    position: relative;
    overflow: hidden;
}
.vehicleModel_vehicleHeaderImage{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
}
.vehicleModel_vehicleHeaderImageEmpty{
    /* min-height: 25rem; */
    min-height: 28rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.vehicleModel_specList{
    gap: 1.1rem 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    margin-top: 2rem;
}
.vehicleModel_specItem:nth-child(2n){
    margin-left: 2rem;
}
.vehicleModel_specItem{
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 1.7rem;
}
.vehicleModel_specItem_left{
    width: 3.5rem;
    height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.6rem;
}
.vehicleModel_specItem_right{
    flex: 1;
}
.vehicleModel_Box, .vehicleVariant_Box, .profileVehicle_Box{
    width: 100%;
    border-radius: 2rem;
    box-shadow: 0rem 0rem 1rem 0rem rgba(125, 201, 254, 0.20);
    background-color: #FFF;
    padding-top: 1.3rem;
    margin-top: -2rem;
    z-index: 5;
    position: relative;
}
.vehicleModel_categoryOuter{
    margin-top: 4rem;
}
.vehicleModel_category{
    display: flex;
    justify-content: space-between;
}
.vehicleModel_categoryItem{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.9rem;
    width: 7.5rem;
}
.vehicleModel_categoryImageOuter{
    width: 3.5rem;
}
.vehicleModel_SeriesSale{
    padding-top: 3.1rem;
}
.vehicleModel_SeriesSale_title{
    margin-top: 2rem;
}
.vehicleModel_SeriesSale_content{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 1.1rem;
}
.vehicleModel_SeriesSale_contentItem{
    border-radius: 2rem;
    border: 0.1rem solid #F0FCFF;
    background: #FFF;
    box-shadow: 0rem 0rem 1rem 0rem rgba(125, 201, 254, 0.20);
    overflow: hidden;
}
.vehicleModel_SeriesSale_contentItem_header{
    background-color: #E6FAFF;
    padding: 0.7rem 1.7rem
}
.vehicleModel_SeriesSale_contentItem_body{
    padding: 0 1.7rem
}
.vehicleModel_SeriesSale_contentItem_bodyRow{
    border-top: 0.1rem solid #E6FAFF;
    padding: 2rem 0 1.7rem;
}
.vehicleModel_SeriesSale_contentItem_bodyRow_price{
    margin-top: 1.1rem;
}
.vehicleModel_SeriesSale_contentItem_bodyRow:first-child{
    border-top: none
}
.vehicleModel_SeriesSale_button{
    margin-top: 0.9rem;
}
.vehicleModel_SeriesSale_contentItem_bodyButtonRow{
    display: flex;
    gap: 0.9rem;
    align-items: center;
    margin-top: 1.1rem;
}
.vehicleModel_placementBannerMargin, .vehicleVariant_placementBannerMargin{
    margin-top: 2.4rem;
}
.vehicleModel_dealerMargin, .vehicleVariant_dealerMargin{
    margin-top: 3.8rem;
}
.vehicleModel_ownershipCost{
    margin-top: 2.4rem;
}
.vehicleModel_ownershipCostBox{
    width: 100%;
    border-radius: 2rem;
    border: 0.1rem solid #99D8EA;
    box-shadow: 0rem 0rem 1rem 0rem rgba(125, 201, 254, 0.20);
    padding: 0 1.7rem 1.6rem
}
.vehicleModel_ownershipCostBox_title{
    text-align: center;
    padding-top: 2rem;
    padding-bottom: 1.7rem;
}
.vehicleModel_ownershipCostBox_row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2.5rem;
}
.vehicleModel_ownershipCostBox_row:first-child{
    margin-top: 2.3rem;
}
.vehicleModel_ownershipCostBox_buttonRow{
    margin-top: 2.6rem;
    display: flex;
    align-items: center;
    gap: 0.9rem;
}

/* vehicle review */
.vehicleReview{
    margin-top: 2.6rem;
}
.vehicleReview_list{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto;
    grid-row-gap: 1.9rem;
    grid-column-gap: 1rem;
    margin-top: 1rem;
}
.vehicleReview_item{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}
.vehicleReview_item_imageOuter{
    width: 6.2rem;
    height: 6.2rem;
    position: relative;
}
.vehicleReview_item_image{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

/* vehicle Review News */
.vehicleReviewNews{
    margin-top: 2.8rem;
}
.vehicleReviewNews_titleRow{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.vehicleReviewNews_titleImage{
    width: 3rem
}
.vehicleReviewNews_list{
    margin-top: 1.7rem;
    gap: 2.5rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}
.vehicleReviewNews_listItem{
    display: flex;
    align-items: stretch;
    gap: 1.1rem;
}
.vehicleReviewNews_listItem_content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}
.vehicleReviewNews_listItem_content_title{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.vehicleReviewNews_listItem_imageOuter{
    width: 10rem;
    height: 10rem;
    position: relative;
}
.vehicleReviewNews_listItem_image{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 1.1rem;
}
.vehicleReviewNews_listItem_content_bottom{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.8rem;
}
.vehicleReviewNews_listItem_content_bottom_date{
    display: flex;
    flex: 1;
    justify-content: flex-end;
}
.vehicleReviewNews_listItem_content_bottom_date_text{
    white-space: nowrap;
}

/* vehicle hotupcoming */
.vehicleBrands_hotUpcoming{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.vehicleBrands_hotUpcoming_item{
    width: 100%;
    /* min-height: 18.2rem; */
    display: flex;
    align-items: flex-end;
    /* padding-top: 5rem; */
}
.vehicleBrands_hotUpcoming_itemBox{
    /* min-height: 13.2rem; */
    width: 100%;
    border-radius: 2rem;
    box-shadow: 0rem 0rem 1rem 0rem rgba(125, 201, 254, 0.20);
    position: relative;
    padding-top: min(2.2rem, 5.7142%);
    padding-bottom: min(2.2rem, 5.7142%);
    padding-left: min(1.6rem, 4.1558%);
    padding-right: min(16rem, 40%);
    display: flex;
    align-items: stretch;
    height: 100%
}
.vehicleBrands_hotUpcoming_itemBox_image{
    /* width: 16rem; */
    width: 40%;
    /* height: 10.6rem; */
    /* height: inherit; */
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: 16rem;
    /* padding-top: 27.5324%; */
    height: 100%;
}
.vehicleBrands_hotUpcoming_itemBox_imageInner{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
}
.vehicleBrands_hotUpcoming_itemBox_content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}
.vehicleBrands_hotUpcoming_itemBox_content_top{
    display: flex;
    justify-content: flex-end;
    position: relative;
    z-index: 3;
    height: 3rem;
}
.vehicleBrands_hotUpcoming_itemBox_content_topBox{
    display: block;
}
.vehicleBrands_hotUpcoming_itemBox_content_bottom{
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    height: 6.4rem;
    max-height: 58.5rem;
}
.vehicleBrands_hotUpcoming_itemBox_content_bottom_title{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 1.6rem;
    line-height: 1.3;
}
.vehicleBrands_hotUpcoming_itemBox_content_bottom_row{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.vehicleBrands_hotUpcoming_itemBox_content_bottom_row_text{
    flex: 1;
    line-height: normal;
    font-size: 1.4rem;
    /* overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical; */
}
.vehicleBrands_hotUpcoming_itemBox_content_bottom_row_text2{
    line-height: normal;
    font-size: 1.2rem;
}
.vehicleBrandsPopularCarMargin, .vehicleVariantPopularCarMargin{
    margin-top: 2.9rem;
}
.vehicleBrandsPlacementMargin{
    margin-top: 1.7rem;
    margin-bottom: 3.2rem;
}

/* vehicle variant */
.vehicleVariant_header_titlePrice{
    margin-top: 0.6rem;
}
.vehicleVariant_header_titleMonlthPrice{
    margin-top: 0.3rem;
}
.vehicleVariantSpec{
    margin-top: 2.8rem;
}
.vehicleVariantSpec_header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.vehicleVariantSpec_header_image{
    width: 3rem;
}
.vehicleVariantSpec_Contant{
    width: 100%;
    border-radius: 2rem;
    border: 0.1rem solid #99D8EA;
    box-shadow: 0rem 0rem 1rem 0rem rgba(125, 201, 254, 0.20);
    padding: 0 1.7rem 2.7rem;
    margin-top: 1.4rem;
}

/* vehicle Filter */
.vehicleFilterHeader{
    position: sticky;
    top: 0;
    left: 0;
    background-color: #FFFFFF;
    z-index: 4;
}
.vehicleFilterHeader_inner{
    padding-bottom:2rem
}
.vehicleFilterHeader_leftCat{
    width: 12rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F7F7F7;
    position: absolute;
    left: 0;
    top: 100%;
}
.vehicleFilter{
    display: flex;
    flex-direction: row;
    gap: 0.9rem;
}
.vehicleFilter_left{
    min-width: 12rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F7F7F7;
}
.vehicleFilter_left_item{
    width: 100%;
    padding: 1.5rem 1.2rem 1.5rem 2rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.vehicleFilter_left_item_active{
    background-color: #FFFFFF
}
.vehicleFilter_left_item_activeLine{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 0.5rem;
    background: linear-gradient(0deg, #E91C21 0%, #E91C21 100%), #FFF;
}
.vehicleFilter_right{
    flex: 1;
    padding-right: 2rem;
}
.vehicleFilter_brand_content{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto auto;
    gap: 0.8rem .7rem;
    padding-top: 0.8rem;
    padding-bottom: 2.6rem;
}
.vehicleFilter_brand_item{
    min-height: 3.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2rem;
    background-color: #F2F2F2;
    text-align: center;
    padding: 0.5rem 1rem
}
.vehicleFilter_brand_item_active{
    background-color: var(--color-red);
}
.vehicleFilter_brand_selectMore{
    grid-column: span 2;
}
.vehicleFilter_price{
    padding-bottom: 4.6rem;
}
.vehicleFilter_price_content{
    padding-top: 2.6rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto auto;
    grid-gap: 0.8rem .7rem;
    gap: 0.8rem .7rem;
}
.vehicleFilter_bodyType{
    padding-bottom: 2.6rem;
}
.vehicleFilter_bodyType_content{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    gap: 1.6rem .9rem;
    padding-top: 1.9rem;
}
.vehicleFilter_bodyType_item{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}
.vehicleFilter_list{
    padding-bottom: 2.6rem;
}
.vehicleFilter_list_content{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.7rem;
    margin-top: 0.9rem;
}
.vehicleFilter_bottomNavigation{
    background-color: var(--bottomNavigation-bg);
    box-shadow: 0rem -0.4rem 1.3rem 0rem rgba(153, 216, 234, 0.48);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 6.5rem;
    gap: 0.9rem;
}
.vehicleFilter_bottomNavigation_reset{
    width: 10rem;
    display: flex;
    justify-content: center;
}
.vehicleFilter_bottomNavigation_search{
    flex: 1;
    background-color: var(--color-red);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4.7rem;
    border-radius: 3rem;
    gap: 0.5rem;
}

/* vehicle Filter Result */
.vehicleFilterResult_bottom{
    height: 6.5rem;
    box-shadow: 0rem -0.4rem 1.3rem 0rem rgba(153, 216, 234, 0.48);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 9;
}
.vehicleFilterResult_bottomButton{
    flex: 1 1;
    background-color: var(--color-red);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5.2rem;
    border-radius: 3rem;
    gap: 0.5rem;
}
.vehicleFilterResult_matchesFoundBox{
    margin-top: 1.6rem
}
.vehicleFilterResult_vehicleListByBrandOuter{
    margin-top: 1.1rem;
}
.vehicleFilterResult_searchFilterList{
    padding-top: 1.1rem;
    width: 100%;
    display: flex;
}
.vehicleFilterResult_searchFilterWrap{
    display: flex;
    align-items: center;
    gap: 0.8rem;
}
.vehicleFilterResult_searchFilterItem{
    background-color: var(--color-red);
    height: 2.8rem;
    min-width: 9.7rem;
    padding: 0 1.3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10rem;
}
.vehicleFilterResult_placementBannerMargin{
    margin-top: 2.1rem;
}
.vehicleFilterResult_searchFilterItem_closeIcon{
    width: 1.6rem;
    height: 1.6rem;
    color: white;
}
.vehicleFilterResult_searchFilterItem_text{
    text-align: center;
    flex: 1;
    white-space: nowrap;
}

/* vehicle Gallery */
.vehicleGallery_title{
    margin-top: 3.5rem;
}
.vehicleGallery_list{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
    padding-bottom: 2rem;
}
.vehicleGallery_list_title{
    padding-bottom: 1rem;
    padding-top: 1.4rem;
}
.vehicleGallery_list_item{
    border-radius: 1.5rem;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
    position: relative;
    background-color: var(--color-lightGrey);
}
.vehicleGallery_list_item img{
    position: absolute; 
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%);
    width: 100%;
}
.vehicleGallery_list_item_main{
    grid-column: span 2;
}

/* compare car */
.compareVehicle{
    margin-top: 2.5rem;
}
.compareVehicle_vsContainer{
    max-width: 35.3rem;
    width: 100%;
    padding-top: min(67.98%, 24rem);
    margin: 0 auto;
    position: relative;
    margin-top: 1.8rem;
}
.compareVehicle_vsContainer_text{
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    /* font-size: 5vh; */
    transform: translate(-50%, -50%);
}
.compareVehicle_vsContainer_text_v{
    font-size: 5.4rem;
}
.compareVehicle_vsContainer_text_s{
    font-size: 5.4rem;
}
.compareVehicle_vsContainer_box{
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.compareVehicle_vsContainer_vBox{
    background-image: url('/assets/images/red_compare_bg.svg');
    position: absolute;
    top: 0;
    bottom: 20.16%;
    left: 0;
    right: 43.62%;
}
.compareVehicle_vsContainer_sBox{
    background-image: url('/assets/images/blue_compare_bg.svg');
    position: absolute;
    bottom: 0;
    top: 20.16%;
    right: 0;
    left: 43.62%;
}
.compareVehicle_vsContainer_boxInner{
    position: relative;
    width: 100%;
    height: 100%;
}
.compareVehicle_vsContainer_boxEmpty{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.compareVehicle_vsContainer_boxEmpty_left{
    padding-right: 2rem;
}
.compareVehicle_vsContainer_boxEmpty_right{
    padding-left: 2rem
}
.compareVehicle_vsContainer_boxInner_imageOuter{
    width: 100%;
    height: 100%;
    position: relative;
}
.compareVehicle_vsContainer_boxInner_imageOuterReverse{
    transform: scaleX(-1);
}
.compareVehicle_vsContainer_boxInner_title{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    flex: 1
}
.compareVehicle_vsContainer_boxInner_image{
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
}
.compareVehicle_vsContainer_boxInner_button{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    border-radius: 5rem;
}
.compareVehicle_vsContainer_boxInner_title_v{
    position: absolute;
    left: 13.70%;
    right: 14.72%;
    top: 8.76%;
}
.compareVehicle_vsContainer_boxInner_image_v{
    position: absolute;
    left: 18.78%;
    right: 34.51%;
    top: 31.95%;
    bottom: 32.47%;
}
.compareVehicle_vsContainer_boxInner_button_v{
    position: absolute;
    left: 13.70%;
    right: 29.94%;
    bottom: 6.70%
}
.compareVehicle_vsContainer_boxInner_title_s{
    position: absolute;
    right: 13.70%;
    left: 29.94%;
    top: 8.76%;
}
.compareVehicle_vsContainer_boxInner_image_s{
    position: absolute;
    right: 18.78%;
    left: 34.51%;
    top: 31.95%;
    bottom: 32.47%;
}
.compareVehicle_vsContainer_boxInner_button_s{
    position: absolute;
    right: 13.70%;
    left: 14.72%;
    bottom: 6.70%
}
.compareVehicle_popular{
    margin-top: 4.4rem;
}
.compareVehicle_popularContainer{
    overflow: auto !important;
    width: 100%;
    display: flex;
}
.compareVehicle_popularWrap{
    display: flex;
    align-items: center;
    gap: 0.9rem;
}
.compareVehicle_popularItem{
    width: 29.8rem;
    height: 20.5rem;
    padding: 1.2rem 1.3rem 1rem;
    border-radius: 2rem;
    border: 0.1rem solid #DCDCDC;
    position: relative;
}
.compareVehicle_popularItem_vs{
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -90%);
}
.compareVehicle_popularItem_vs_v, .compareVehicle_popularItem_vs_s{
    font-size: 2.6rem;
}
.compareVehicle_popularItem_imageReverse{
    transform: scaleX(-1)
}
.compareVehicle_popularItem_imageOuter{
    width: 9.2rem;
    height: 6.9rem;
    position: relative
}
.compareVehicle_popularItem_image{
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.compareVehicle_popularItem_row{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.compareVehicle_popularItem_row_left{
    padding-right: 1.5rem;
    width: 50%;
    display: flex;
    justify-content: center;
}
.compareVehicle_popularItem_row_right{
    padding-left: 1.5rem;
    width: 50%;
    display: flex;
    justify-content: center;
}
.compareVehicle_popularItem_button{
    height: 3.6rem;
    border-radius: 5rem;
    background-color: darkgreen;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    overflow: hidden;
    margin-top: 0.9rem
}
.compareVehicle_popularItem_button_box{
    width: 55%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.compareVehicle_popularItem_button_red{
    clip-path: polygon(0 0, 100% 1%, 90% 100%, 0% 100%);
    margin-right: -3%;
}
.compareVehicle_popularItem_button_blue{
    clip-path: polygon(10% 0, 100% 1%, 100% 100%, 0% 100%);
    margin-left: -3%;
}
.comparePopularCarMargin{
    margin-top: 4.7rem;
}

/* compare Vehicle Detail */
.compareVehicleDetail_container{
    /* background-color: #F7F7F7; */
    overflow-x: scroll;
    width: 100%;
    display: flex;
}
.compareVehicleDetail_container::-webkit-scrollbar {
    display: none;
}
.compareVehicleDetail_header_title{
    padding-top: 1.6rem;
}
.compareVehicleDetail_header{
    background-color: #FFF;
    padding-top: 1rem;
    padding-bottom: 2rem;
    border-bottom-left-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
    box-shadow: 0rem 1.2rem 2rem -1rem rgba(153, 216, 234, 0.48);
    width: 100%;
    /* position: sticky;
    left: 0;
    top: 0; */
    z-index: 6;
    /* margin-bottom: 1rem; */
    position: relative;
}
/* .compareVehicleDetail_headerIsSticky{
    position: fixed;
    top: 0;
    left: 0;
} */
.compareVehicleDetail_vehicleListWrap{
    display: flex;
    gap: 0.9rem;
    align-items: center;
    padding-left: 12rem;
    padding-right: 2rem;
    padding-bottom: 0.1rem;
}
.compareVehicleDetail_vehicleItem{
    border-radius: 2rem;
    border: 0.1rem solid #99D8EA;
    width: 13.1rem;
    height: 16.6rem;
    padding: 1.2rem 1.2rem 1.2rem .9rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.compareVehicleDetail_vehicleItemBox{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.compareVehicleDetail_vehicleItem_text{
    height: 3.65rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.compareVehicleDetail_vehicleItem_imageOuter{
    width: 9.2rem;
    height: 6.049rem;
    position: relative;
}
.compareVehicleDetail_vehicleItem_image{
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.compareVehicleDetail_vehicleItem_button{
    width: 100%;
    height: 2.5rem;
    display: flex;
    border-radius: 5rem;
    background-color: #99D8EA;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
}
.compareVehicleDetail_vehicleItemEmpty{
    justify-content: center;
    border-style: dashed;
}
.compareVehicleDetail_containerLeft{
    width: 12rem;
}
.compareVehicleDetail_containerLeft_header{
    width: 12rem;
}

/* compare Table */ 
.compareTableBox{
    width: 100%;
    height: 100%;
}
.compareTableGrid{
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(188, auto);
    overflow-x: scroll;
    justify-items: start;
    justify-content: start;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.compareTableGrid::-webkit-scrollbar {
    display: none;
}

.compareTableGridHead, .compareTableGridBody {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: center;
    white-space: break-spaces;
    text-align: left;
    background-color: white;
    word-break: break-word;
}
.compareTableGridHead{
    width: 12rem;
}
.compareTableGridBody{
    padding: 1rem;
    width: 14rem;
    border: 0.1rem solid #F7F7F7;
    border-left: none;
}
.compareTableGridBodyVehicle{
    padding: 1rem 4.5rem
}
.compareTableColorColumn{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.4rem;
}
.compareTableColorRow{
    display: flex;
    gap: 0.4rem;
    align-items: flex-start;
}
.compareTableColor{
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    margin-top: 0.2rem;
    border: 1px solid black;
}
.compareTableText{
    display: flex;
    flex: 1;
}
.compareTableGridCatHeader{
    grid-column: span 10;
    position: sticky;
    left: 0;
    width: auto;
    max-width: 50rem;
    z-index: 4;
    height: 5.3rem;
}
.compareTableGridCatHeaderHavePlacement{
    height: 31.3rem;
}
.compareTableGridCatHeaderHavePlacementPosition{
    position: absolute;
    top: 1rem;
    bottom: 5.3rem;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.compareTableGridCatHeaderInnerBackground{
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: #F7F7F7;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.compareTableGridCatHeaderInner{
    padding: 1rem;
    width: 100%;
    /* background-color: #FFF; */
    background-color: #FFF;
}
.compareTableGridHeader{
    padding: 1rem;
    position: sticky;
    left: 0;
    color: #888;
    border: 0.1rem solid #F7F7F7
}
.compareStickyLeft{
    position: sticky;
    left: 0;
    background-color: #FFF;
    z-index: 5;
}
.compareStickyTop{
    position: sticky;
    top: 0;
    background-color: #FFF;
    z-index: 5;
}
.compareFixedTop{
    position: fixed;
    top: 0;
    background-color: #FFF;
    z-index: 5;
    height: 18.6rem;
}
.compareTableGridColum_closeIcon{
    color: var(--color-red);
}
.compareTableGridColum_checkIcon{
    color: var(--color-darkBlue2);
}
/* .compareTableBox table {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
.compareTableBox thead, .compareTableBox tbody{
    display: flex;
    flex-direction: row;
}
.compareTableBox tr {
    display: flex;
    flex-direction: column;
}
.compareTable_row, .compareTable_rowContent, .compareTable_rowHeader {
    border: 0.5rem solid #000;
    border-collapse: collapse;
}
.compareTable{
    border-spacing: 0;
    table-layout: fixed;
}
.compareTable_titleRow{
    position: sticky;
    top: 0;
    background-color: white;
    width: 100%;
    z-index: 2;
}
.compareTable_titleRowHeader{
    height: 5.3rem;
    padding-left: 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: sticky;
    left: 0;
    background-color: #fff;
    width: fit-content;
}
.compareTable_row{
    display: flex;
    align-items: center;
}
.compareTable_rowHeader{
    width: 11.8rem;
    padding: 1rem 1rem 1rem 2rem;
    color: #888;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: sticky;
    left: 0;
    background-color: #fff;
}
.compareTable_rowContent{
    width: 14rem;
    padding: 1rem
}
.compareTable_rowSpace{
    
}
.compareTable_rowSpaceHeader{
    background-color: #f7f7f7;
    height: 0.9rem;
    width: 100%;
} */

/* search */
.searchBox{
    margin-top: 0.9rem;
}
.search_textInput{
    height: 4.5rem;
    border: transparent;
    background-color: #F1F1F1;
    border-radius: 5rem;
    padding-left: 5rem;
    min-height: 3.6rem;
    padding-right: 1.2rem;
    line-height: 3.4rem;
}
.search_textInput_leftIcon{
    /* width: 2.4rem;
    margin-left: 1.8rem; */
    width: 1.8rem;
    margin-left: 0.8rem;
    padding-bottom: 0.3rem;
}
.search_topBrand{
    grid-template-columns: 1fr 1fr 1fr 1fr;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-gap: 0.6rem;
    margin-top: 1.2rem;
}
.search_topBrand_button{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.1rem solid var(--color-grey2);
    border-radius: 0.4rem;
    min-height: 3rem;
}
.search_topBrand_button_text{
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

/* privacy policy */
.privacyPolicyContainer {
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
}
.privacyPolicyContent{
    height: 100%;
    width: 100%;
    padding-top: 1.9rem;
    padding-bottom: 2rem;
}
.privacyPolicyContentBox{
    width: 100%;
    height: 100%;
    background-color: #F2F2F2;
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
}
.privacyPolicyContainer ul{
    list-style-type: disc;
}
.privacyPolicyContainer ul li{
    margin-left: 2.1rem;
}
.privacyPolicyContactUs{
    padding-left: 3.5rem;
    padding-right: 3.5rem;
    padding-bottom: 2rem;
}
.privacyPolicyContentBox_header{
    padding: 0.9rem 0 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
}
.privacyPolicyContentBox_headerDate{
    color: #AAA;
}
.privacyPolicyContentBox_content{
    height: 100%;
    position: relative;
    margin-right: 0.9rem;
}
.privacyPolicyContentBox_contentInner{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
.privacyPolicyContentBox_contentTextBox{
    padding-right: 1.4rem;
    padding-left: 1.4rem;
    padding-bottom: 2rem;
    padding-top: 2rem;
}
.privacyPolicyContentBox_contentTextBox .fontWeight_semiBold{
    margin-top: 1.4rem;
    margin-bottom: 1rem;
}
.privacyPolicyContentBox_dividingLineOuter{
    padding-left: 1.4rem;
    padding-right: 2.6rem;
}

/* ranking */
.rankingPage_topBackground{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 33.2rem;
    background: linear-gradient(101deg, #DBF7FF 0%, #72ACFF 100%), #D9D9D9;
    z-index: 1;
}
.rankingPage_topBackgroundInner{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 10.7rem;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);
    z-index: 2;
}
.rankingPage_header{
    z-index: 9;
    position: relative;
}
.rankingPage_contentInner{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    /* padding-top: 13.8rem; */
    padding-bottom: 2rem;
    box-shadow: 0rem 0rem 1rem 0rem rgba(125, 201, 254, 0.31);
    margin: 0.4rem 1.6rem;
    border-radius: 2rem;
    padding: 0 2rem;
}
.rankingPage_content_bgText{
    background-image: url('/assets/images/ranking_speed_test.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    top: 3.5rem;
    left: 0;
    position: absolute;
    width: 17rem;
    height: 6.8rem;
    z-index: 4;

    /* position: absolute;
    top: 3.5rem;
    left: 0;
    z-index: 4;
    font-family: Poppins;
    font-size: 29.77.3rem;
    font-style: italic;
    font-weight: 800;
    line-height: 115.5%;
    background: linear-gradient(90deg, #FFF 1.57%, rgba(255, 255, 255, 0.19) 89.63%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
}
.rankingPage_content_bgImage{
    background-image: url('/assets/images/ranking_cup.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 2.4rem;
    right: 0;
    width: 13.7rem;
    height: 15.6rem;
    z-index: 3;
}
.rankingPage_item{
    background-color: white;
    width: 100%;
    min-height: 7.5rem;
    z-index: 5;
    position: relative;
    border-radius: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* box-shadow: 0rem 0rem 1rem 0rem rgba(125, 201, 254, 0.20); */
    padding: 1.8rem 0rem;
    gap: 0.8rem;
}
.rankingPage_item_ranking{
    width: 2.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.rankingPage_item_image{
    width: 8.6rem;
    height: 5.7rem;
}
.rankingPage_item_content{
    flex: 1
}
.rankingPage_item_sec{
    text-align: right;
    width: 6.8rem;
}
.homePage_rankingBox_content .rankingPage_item{
    padding: 0.6rem;
    min-height: 5rem;
}

/* calculator */
.calculator_container{
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.calculator_background_red{
    background-color: var(--color-red);
    border-radius: 2rem;
}
.calculator_header_title{
    display: flex;
    align-items: center;
    gap: 0.8rem;
    justify-content: center;
    padding-top: 3.4rem;
}
.calculator_header_titleRoadTax{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 3.1rem;
    padding-bottom: 5.4rem;
    flex-direction: column;
}
.calculator_header_title_secondRow{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding-top: 1.6rem;
    padding-bottom: 3.6rem;
}
.calculator_header_vertical_line{
    width: 0.1rem;
    background-color: var(--color-red3);;
    height: 4.8rem;
}
.calculator_background_pink{
    background-color: var(--color-red4);
    margin-top: -2rem;
    border-radius: 2rem;
    padding: 2.2rem 1.6rem;
}
.calculator_inputContainer{
    display: flex;
    flex-direction: column;
    gap: 0.9rem;
}
.calculator_inputBox{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.5rem 0 2.4rem;
    background-color: var(--color-red3);
    height: 4.8rem;
    border-radius: 5rem;
}
.calculator_inputRow_expendIcon{
    width: 2.4rem;
    height: 2.4rem;
}
.calculator_textInputContainer{
    margin-top: 3.2rem;
    gap: 0.9rem;
    display: flex;
    flex-direction: column;
}
.calculator_textInput{
    background-color: var(--color-red3);
    border: none;
    height: 4.8rem;
    padding-left: 2.4rem;
    padding-right: 1.5rem;
    border-radius: 5rem;
    color: var(--color-red);
}
.calculator_slider{
    margin-top: 3.2rem;
    padding-bottom: 3rem;
}
.calculator_slider_markLabel{
    transform: translate(-20%, 0%);
}
.mantineCustome-Slider-markWrapper:last-child .calculator_slider_markLabel{
    transform: translate(-80%, 0%);
}
.calculator_slider_sliderRow{
    margin-top: 1.9rem;
}
.calculator_slider_titleRow{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.calculator_slider_valueBox{
    width: 6.2rem;
    height: 2.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-red3);
    border-radius: 5rem;
}

/* dealer */
.dealer_search_row{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
}
.dealer_search_row_item{
    height: 3.1rem;
    border-radius: 2rem;
    background-color: #F2F2F2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.5rem;
    width: 100%;
}
.dealer_search_row_item_icon{
    width: 1.5rem;
    height: 1.5rem;
}
.dealer_search_row_button{
    height: 3.1rem;
    min-width: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2rem;
    align-self: end;
}

/* detail */
.dealerTitle_row{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.dealerTitle_row_tag{
    width: 3rem;
    height: 1.8rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 5rem;
    text-align: center;
    margin-right: 0.8rem;
    transform: translateY(-10%);
}
.dealerTitle_tagList{
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    margin-top: 0.8rem;
}
.dealerTitle_tagList_active{
    display: flex;
    gap: 0.4rem;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.dealerTitle_tagList_inactive{
    display: flex;
    gap: 0.4rem;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.dealerTitle_info_row_icon{
    align-self: flex-start;
}
.dealerTitle_info_row{
    margin-top: 1.2rem;
}
.dealerTitle_info_row_tagWidth{
    width: 10rem;
}
.dealerTitle_info_content_container{
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}
.dealerTitle_info_content_row{
    display: flex;
    align-content: flex-start;
    flex-direction: row;
    gap: 0.8rem;
    align-items: center;
}
.dealerTitle_info_content_row_inner_left{
    display: flex;
    align-content: flex-start;
    flex-direction: row;
    gap: 0.8rem;
    align-items: center;
    flex: 1;
}
.dealerTitle_info_content_row_inner_right{
    width: 5rem;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}
.dealerTitle_info_content_row_inner_right_background{
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #99D8EA;
    transform: rotate(45deg);
    border-radius: 0.3rem;
}
.dealerTitle_info_content_row_inner_right_icon{
    width: 2.2rem;
    height: 2.2rem;
    transform: rotate(-45deg);
    color: #FFF;
}
.dealerTitle_info_content_row_icon{
    width: 2.4rem;
    height: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #edfbff;
    border-radius: 50%;
}
.dealerTitle_bottom_row{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 2rem;
    gap: 0.8rem;
}

.phoneCallListModel_title{
    background: var(--vehicleBrandTitle-linear);
    height: 3.7rem;
    display: flex;
    align-items: center;
}
.phoneCallListModel_href{
    display: flex;
    flex-direction: column;
}
.phoneCallListModel_href_box{
    height: 5.6rem;
    width: 100%;
    border-bottom: 0.1rem solid #EEE;
    display: flex;
    align-items: center;
    gap: 1rem;
}
.dealerDetail_dealerSection_margin{
    margin-top: 2rem;
}
.dealerDetail_box{
    box-shadow: 0rem .8rem 1.3rem 0rem rgba(153, 216, 234, 0.48);
    margin: 1.6rem 0 .4rem;
    padding-bottom: 2rem;
    border-radius: 2rem;
}

.phoneTitle_row{
    margin-bottom: 1.2rem;
    position: relative;
}
.phoneTitle_background{
    position: absolute;
    width: 100%;
    height: 0.1rem;
    background-color: #B3B3B3;
    top: 50%;
    opacity: 0.2;
}
.phoneTitle_box{
    border-radius: 3.1rem;
    border: 0.1rem solid #99D8EA;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: 1.6rem;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    position: relative;
    margin-left: 2.8rem;
}

.calculator_formRowMargin{
    margin-top: 3.2rem;
    gap: 0.9rem;
    display: flex;
    flex-direction: column;
}


/* modal */
/* base modal */
/* center modal start */
.center_modal_mantine_root{
    position: absolute;
    z-index: 9;
}
.center_modal_mantine_modal{
    background: unset;
    width: 100%;
    padding: 0;
}
.center_modal_mantine_body{
    height: 100%;
}
.center_modal_mantine_inner{
    top: -0.2rem;
    bottom: -0.2rem;
}
.center_modal_container{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 2rem;
    padding-right: 2rem;
    position: relative;
}
.center_modal_closeButtonPosition{
    position: absolute;
    top: 2rem;
    right: 2rem;
    z-index: 3;
}
.center_modal_closeButton{
    width: 3.6rem;
    height: 3.6rem;
    padding: 0.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 11;
}
.center_modal_overlay{
    background-color: rgba(0,0,0,0.8);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.center_modal{
    width: 100%;
    /* height: 100%; */
    max-height: 40rem;
    background-color: white;
    /* border-radius: 1rem; */
    /* overflow: hidden; */
    position: relative;
    z-index: 2;
    overflow-y: scroll
}
.center_modal_rowItem{
    height: 5.6rem;
    width: 100%;
    border-bottom: 0.1rem solid #EEE;
    display: flex;
    align-items: center;
}
/* center modal end */
.placement_modal{
    width: 100%;
    max-width: 32rem;
    position: relative;
    z-index: 2;
    overflow-y: hidden;
}

.rankingNum{
    width: 2.4rem;
    height: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.rankingNum1{
    color: rgb(255, 229, 185);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-58%, -43%);
}
.rankingNum2{
    color: #E9EDF8;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-56%, -42%);
}
.rankingNum3{
    color: #FFCEB8;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-56%,-40%);
}

/* Modify Code */
/* Component */
/* Image */

/* Image Logo */
.logoSecondary{
    width: 6.5rem;
    height: 6.5rem;
    border-radius: 50%;
    transform: translateY(-2rem);
}

/* Dealer */
.rankingList_component{
    font-size: 1rem;
}
.rankingList_component_section{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    padding-bottom: 2rem;
    box-shadow: 0rem 0rem 1rem 0rem rgba(125, 201, 254, 0.31);
    margin: 0.4rem 0 1.6rem;
    border-radius: 2rem;
    padding: 0 2rem;
}
.rankingList_component_section_header{
    height: 7rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
.rankingList_component_section_header_logo{
    background-image: url(/assets/images/logo.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 14rem;
    height: 1.6rem;
    z-index: 5;
}
.rankingList_component_section_header_rightText{
    background: linear-gradient(90deg, #E91C21 1.57%, rgba(233, 28, 33, 0.41) 89.63%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.rankingList_component_section_footer_viewMore{
    margin-top: 1.2rem;
}

.footer{
    background-color: #1e1e1e;
    padding-top: 2rem;
    padding-bottom: 3rem;
}
.footer .footer_container{
    display: flex;
    flex-direction: column;
}
.footer .footer_top{
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}
.footer .footer_logo{
    width: 12rem;
}
.footer .footer_follow_us{
    margin-top: 2rem;
    gap: 0.4rem;
    display: flex;
    flex-direction: column;
}
.footer .footer_follow_us_list{
    display: flex;
    align-items: center;
    gap: 0.7rem;
    flex-wrap: wrap;
}
.footer .footer_follow_us_list_media_icon{
    width: 2.8rem;
    cursor: pointer;
}
.footer .footer_follow_us_list_media_icon_tiktok{
    background-color: #ffffff;
    border-radius: 50%;
    display:flex ; 
}
.footer .footer_bottom{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 2rem;
    flex-direction: column;
    border-top: 1px solid rgb(87, 87, 87);
    padding-top: 1.6rem;
}
.footer .footer_bottom_list {
    display: flex;
    gap: 1rem;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.about_us_container_text{
    padding-bottom: 2rem;
    padding-top: 2rem;
}

/** Google Ads CSS **/
ins.adsbygoogle[data-ad-status="unfilled"] {
    display: none !important;
}

/** Tyre related CSS START **/
.popularTyreBrands{
    min-height: 12rem;
}
.tyreSizeImage{
    padding-top: 10px;
}
/* tyre image mask empty */
.exmptyTyreImageMask{
    mask-image: url('/assets/images/empty/empty_tyre.png');
    mask-size: cover;
    width: 100%;
    height: 100%;
}
.tyreModel_tyreHeader{
    position: relative;
    display: flex;
    justify-content: center;
}
.tyreModel_tyreImageOuter{
    width: 18rem;
    height: 18rem;
}
.tyreModel_viewTyreSizeButton{
    padding: 0.5rem;
    border-radius: 3rem;
    background: #E91C21;
    width: 10.8rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    right: 1.5rem;
}
.tyreModel_modelTitle{
    text-transform: uppercase;
}
.tyreRatingBox_boxInner{
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    background-color: var(--color-lightGrey);
}
.tyreRatingBox_boxRow{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem 1rem;
}
.tyreRatingBox_desc{
    padding-top: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.tyreVariantBox_row{
    padding-bottom: 1rem;
}
.tyreVariantBox_vehiclelist{
    display: flex;
    width: 100%;
    align-items: center;
}
.tyreVariantBox_detail{
    padding: 1rem;
}
.tyreVariantBox_vehicleImageOuter{
    width: 13.4rem;
    height: 7rem;
    margin-bottom: 0.5rem;
}
.tyreVariantBox_vehicleImageInner{
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 0.5rem;
}
.tyreVariantBox_vehicleImage{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.popularTyreBrandsDetail_title{
    position: absolute;
    background: linear-gradient(90deg, #FF383D 2.27%, #FF9559 108.33%), linear-gradient(90deg, #FF9559 2.27%, #FBE599 108.33%);
    top: -0.4rem;
    left: 0rem;
    right: 0rem;
    padding: 0 2.7rem 0 2.1rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 3.3rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.tyreReviewCategoryBox{
    display: grid;
    grid-gap: 0.2rem 2rem;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    margin-top: 1rem;
}
.tyreReview_comment{
    padding-bottom: 1rem;
    border-bottom: 0.1rem solid #909090;
}
.tyreReviewCategoryBox_inner{
    display: flex;
    flex-direction: column;
}

/** Tyre review **/
.tyreWriteReview_extra{
    height: 15.5rem;
}
.tyreWriteReview_grid{
    display: grid;
    grid-gap: 1.2rem 2rem;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    margin-top: 1rem;
}
.tyreWriteReview_grid_inner{
    display: flex;
    flex-direction: column;
}
.tyreWriteReview_rating_star{
    width: 3rem;
    height: 3rem;
}

/** Tyre search filter input **/
.tyreFilter{
    min-height: calc(100% - 25rem);
}
.tyreFilter_bottomNavigation{
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
}
.searchTyreBoxOuter{
    background-color: #ffffff;
    /* border-radius: 3rem;
    box-shadow: 0rem 0rem 1.3rem 0rem rgb(254 125 125 / 70%); */
    padding: 0.2rem;
    margin: 1.5rem 0;
}
.searchTyreBox{
    height: 5rem;
    display: flex;
    align-items: center;
    grid-gap: 0.2rem;
    background-color: #F1F1F1;
    padding: 0rem;
    border-radius: 0.5rem;
    color: #909090;
    margin-top: 1rem;
}
.tyre_width_textInput{
    padding-left: 1rem;
}
.tyre_width_textInput, .tyre_asr_textInput, .tyre_radius_textInput{
    min-width: 11rem;
    height: 2rem;
    border: transparent;
    background-color: #F1F1F1;
    text-align: center;
}
.searchTyreBoxButtonOuter{
    /* width: 22rem;
    margin: 0 auto; */
    display: flex;
    width: 100%;
}
.searchTyreBoxButtonInner{
    display: flex;
    flex: 1;
    justify-content: center;
    height: 5rem;
    background-color: var(--color-red);
    align-items: center;
    border-radius: 3rem;
}
.tyreFilterPickerBox{
    position: relative;
}
.tyreFilterPickerBoxInner{
    position: relative;
    z-index: 2;
}
.tyreFilterPickerDesc{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    z-index: 1;
}
.tyreFilterPickerDescInner{
    height: 4.9rem;
    background-color: #F1F1F1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.tyreFilterPickerDescSeperate{
    text-align: right;
    width: 33.33%;
    color: #909090;
}
.tyreFilterPickerItem{
    padding-right: 4rem;
    text-align: right;
}

/** Tyre related CSS END **/


/*  */
/*  */
.stick_bottom{
    position: relative;
    height: 6.5rem;
    box-shadow: 0rem -0.4rem 1.3rem 0rem rgba(153, 216, 234, 0.48);
    display: flex;
    align-items: center;
    z-index: 9;
    background-color: white;
}
/* icon */
.icon_24{
    width: 2.4rem;
    height: 2.4rem;
}
.icon_33{
    width: 3.3rem;
    height: 3.3rem;
}
.icon_50{
    width: 5rem;
    height: 5rem;
}

/* button */
.button_red {
    background-color: var(--color-red);
    border-radius: 5rem;
    width: fit-content;
}
.button_red2{
    background-color: var(--color-lightRed2);
    border-radius: 5rem;
    width: fit-content;
}
.button_blue {
    background-color: var(--color-lightBlue5);
    border-radius: 5rem;
    width: fit-content;
}
.button_green {
    background-color: var(--color-lightGreen);
    border-radius: 5rem;
    width: fit-content;
}
.button_padding_sm{
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    border-radius: 5rem;
}
.button_padding_md{
    padding-left: 1.7rem;
    padding-right: 1.7rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    border-radius: 5rem;
}
.button_padding_xxl{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4.6rem;
    border-radius: 5rem;
    width: 100%;
    gap: 0.4rem;
}
.button_size_46{
    width: 4.6rem;
    height: 4.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
}


/* map */
.map_info{
    position: relative;
    /* left: 0;
    right: 0;
    bottom: 0; */
    /* height: 8rem; */
    min-height: 8rem;
    background-color: white;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    padding-top: 1.2rem;
    height: fit-content;
    z-index: 3;
}

.map_info_animationDefault{
    margin-top: 95%
}

.map_info_animationUp{
    animation: animationUp 0.3s;
    animation-fill-mode: both;
}

.map_info_animationDown{
    animation: animationDown 0.3s;
    animation-fill-mode: both;
}

@keyframes animationUp {
    0% { transform: translateY(calc(100% - 23.8rem)) }
    100% { transform: translateY(7rem) }
}

@keyframes animationDown {
    0% { transform: translateY(7rem) }
    100% { transform: translateY(calc(100% - 23.8rem))}
}
.map_info_header{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.map_info_header_left_row2{
    display: flex;
    align-items: center;
}
.map_info_body_row{
    display: flex;
    align-items: flex-start;
    gap: 1.8rem;
}
.map_info_plug{

}
.map_info_plug_header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.map_info_plug_body{
    display: grid;
    grid-template-columns: 5.5rem 1fr 12rem;
    grid-gap: 0.8rem;
    align-items: center;
}
.map_info_plug_footer{
    position: relative;
    height: 6.5rem;
    box-shadow: 0rem -0.4rem 1.3rem 0rem rgba(153, 216, 234, 0.48);
    display: flex;
    align-items: center;
    z-index: 9;
    background-color: white;
}
.map_info_dropdown{
    position: absolute;
    top: 12.4rem;
    right: 1rem;
    z-index: 3;
    width: 4.6rem;
    height: 4.6rem;
    border-radius: 50%;
    background-color: rgba(255,255,255,0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.8rem
}

.google_map_page{
    height: 100%;
    overflow-y: hidden;
}

.google_map_outlet{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.google_map_loading{
    position: absolute;
    z-index: 4;
    top: 12.8rem;
    left: 50%;
    width: 4rem;
    height: 4rem;
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(-50%);
}

.modal_share{
    border-radius: 2rem;
}
.modal_share_header{
    display: flex;
    padding: 1.2rem;
    align-items: center;
    justify-content: space-between
}
.modal_share_body{
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    padding-top: 1.2rem;
    padding-bottom: 2rem;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 1.2rem;
}
.modal_share_body_row{
    display: flex;
    align-items: center;
    gap: 1.2rem;
}
.modal_share_body_copyRow{
    display: flex;
    align-items: center;
    border: 0.1rem solid var(--color-lightGrey2);
    border-radius: 0.4rem;
    padding: 0.4rem;
}
.modal_Share_body_copyRow_coprIcon {
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.modal_Share_body_copyRow_textBox{
    display: flex;
    flex: 1
}
.modal_Share_body_copyRow_textBox_text{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.modal_Share_body_copyRow_copyButton{
    height: 3.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    background-color: var(--color-red);
    border-radius: 0.4rem;
}
.modal_share_linePadding{
    padding-left: 1.2rem;
    padding-right: 1.2rem;
}

.kayauto_point_icon_blueBackground{
    width: 100%; 
    height: 100%;
    border-radius: 50%;
    background: linear-gradient(180deg, #0DAFFF 0%, #3E74FF 100%), #0094FF;
}

/* drawer */
.addMyVehicleDrawer_root{
    position: absolute;
    z-index: 9;
    top: -1px;
}
.addMyVehicleDrawer_drawer{
    position: absolute;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
}
.addMyVehicleDrawer_header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.addMyVehicleDrawer_closeBox{
    padding: 1.6rem;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.addMyVehicleDrawer_closeIcon{
    width: 2rem;
    height: 2rem;
}
.addMyVehicleDrawer_body{
    overflow-y: scroll;
    position: absolute;
    top: 5.2rem;
    left: 0;
    bottom: 0;
    right: 0;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.addMyVehicleDrawer_body::-webkit-scrollbar {
    display: none;
}
.addMyVehicleDrawer_body_form{
    padding-bottom: 10rem;
    position: relative;
}
.addMyVehicleDrawer_body_chooseArchiveVehicle{
    overflow-x: hidden;
    position: relative;
}
.addMyVehicleDrawer_body_chooseArchiveVehicle_box{
    height: 14rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.1rem dashed;
    border-radius: 1rem;
    position: relative;
}
.addMyVehicleDrawer_body_chooseArchiveVehicle_icon{
    background: url('/assets/images/vehicle_image_lightgrey.png');
    width: 20rem;
    height: 9.2rem;
    position: absolute;
    bottom: 0.1rem;
    right: -1rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    pointer-events: none;
}
.addMyVehicleDrawer_body_archiveVehicle_box{
    border-radius: 1rem;
    padding: 1rem;
    min-height: 14rem;
    box-shadow: 0px 0px 20px 0px rgba(153, 216, 234, 0.5);
    margin-top: 2rem;
    margin-bottom: 1rem;
    position: relative;
    overflow: hidden;
}
.addMyVehicleDrawer_body_archiveVehicle_box_isDefault{
    position: absolute;
    top: 1rem;
    left: -2.8rem;
    background-color: var(--color-red);
    width: 10rem;
    height: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(-45deg);
}
.addMyVehicleDrawer_body_archiveVehicle_box_textRow{
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
}
.addMyVehicleDrawer_body_archiveVehicle_box_selectMore{
    position: absolute;
    top: 1rem;
    right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.6rem;
    height: 2.6rem;
    padding: 0.4rem
}
.addMyVehicleDrawer_body_archiveVehicle_vehicleRow{
    display: flex;
    gap: 1.2rem;
    align-items: stretch;
    height: 8rem;
}
.addMyVehicleDrawer_body_archiveVehicle_box_image{
    width: 14rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.addMyVehicleDrawer_body_archiveVehicle_box_tagBox{
    background-color: var(--color-lightBlue5);
    display: flex;
    align-items: center;
    width: fit-content;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 4rem;
    justify-content: center;
    min-width: 8rem;
    text-align: center;
}
.addMyVehicleDrawer_body_archiveVehicle_box_tag{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.addMyVehicleDrawer_body_archiveVehicle_box_textBox{
    flex: 1
}
.addMyVehicleDrawer_body_archiveVehicle_box_text{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.addMyVehicleDrawer_body_archiveVehicle_bottomRow{
    display: flex;
    margin-top: 0.8rem;
}
.addMyVehicleDrawer_body_archiveVehicle_changeVehicleBox{
    display: flex;
    align-items: center;
    justify-content: center;
}

/* archive vehicle */
.addArchiveVehicleDrawer_root{
    position: absolute;
    z-index: 10;
    top: -1px;
}
.addArchiveVehicleDrawer_drawer{
    position: absolute;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
}
.addArchiveVehicleDrawer_header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.addArchiveVehicleDrawer_closeBox{
    padding: 1.6rem;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.addArchiveVehicleDrawer_closeIcon{
    width: 2rem;
    height: 2rem;
}
.addArchiveVehicleDrawer_body{
    overflow-y: scroll;
    position: absolute;
    top: 20.2rem;
    left: 0;
    bottom: 0;
    right: 0;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.addArchiveVehicleDrawer_body::-webkit-scrollbar {
    display: none;
}
.addArchiveVehicleDrawer_road_row{
    display: flex;
    justify-content: space-between;
}
.addArchiveVehicleDrawer_road_item{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
}
.addArchiveVehicleDrawer_road_icon{
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-red);
    padding: 1rem;
}
.addArchiveVehicleDrawer_road_icon_inactive{
    background-color: var(--color-lightGrey3)
}
.addArchiveVehicleDrawer_road_dot{
    flex: 1;
    border-bottom: 1px dotted black;
    height: 2rem;
}
.addArchiveVehicleDrawer_brand_titlebox{
    background: linear-gradient(90deg, #D9D9D9 0%, rgba(255,255,255, 1) 100%);
    height: 3.7rem;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 2;
}
.addArchiveVehicleDrawer_brand_rowBox{
    height: 5.6rem;
    border-bottom: 0.1rem solid #EEE;
    display: flex;
    align-items: center;
    gap: 1rem;
}
.addArchiveVehicleDrawer_brand_iconOutner{
    width: 4rem;
    height: 4rem;
    position: relative
}
.addArchiveVehicleDrawer_brand_icon{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.addArchiveVehicleDrawer_brand_hot_box{
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    grid-template-rows: auto auto;
    grid-row-gap: 1.5rem;
    justify-items: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.addArchiveVehicleDrawer_brand_hot{
    width: 6.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.addArchiveVehicleDrawer_brand_hot_imageOuter{
    width: 5.5rem;
    height: 5.5rem;
    position: relative;
}
.addArchiveVehicleDrawer_brand_hot_image{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
}
.addArchiveVehicleDrawer_brand_hot_text{
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
    text-align: center;
}
.addArchiveVehicleDrawer_body_variant{
    height: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    overflow: hidden;
}
.addArchiveVehicleDrawer_body_variant_left{
    width: 10rem;
    background-color: var(--color-lightGrey);
    min-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.addArchiveVehicleDrawer_body_variant_left::-webkit-scrollbar {
    display: none;
}
.addArchiveVehicleDrawer_body_variant_left_item{
    padding: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.addArchiveVehicleDrawer_body_variant_left_item_active{
    background-color: #FFFFFF;
    border-left: 0.5rem solid var(--color-red);
    padding-left: 0.7rem;
}
.addArchiveVehicleDrawer_body_variant_right{
    display: flex;
    flex-direction: column;
    flex: 1 1;
    position: absolute;
    left: 10rem;
    top: 0;
    bottom: 0;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.addArchiveVehicleDrawer_body_variant_right::-webkit-scrollbar {
    display: none;
}
.addArchiveVehicleDrawer_body_variant_right_item{
    display: flex;
    padding: 1.2rem;
    align-items: center;
    justify-content: flex-start;
}

/* custom */
/* form */
/* input */
.customForm{
    
}
.customForm .customBoxLayout{
    height: 4.5rem;
    border: transparent;
    background-color: var(--color-lightGrey);
    border-radius: 5rem;
}
.customForm .customBoxLayout::placeholder{
    color: var(--textInput-placeholderColor);
    opacity: 1;
}
.customForm .customSelectBoxLayout{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--color-lightGrey);
    height: 4.5rem;
    border-radius: 5rem;
}

.customForm .customIcon_md{
    width: 2.4rem;
    height: 2.4rem;
}

/* drawer */
.selectOptionDrawer_root{
    position: absolute;
    z-index: 9;
    top: -1px;
}
.selectOptionDrawer_drawer{
    position: absolute;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
}
.selectOptionDrawer_header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.selectOptionDrawer_closeBox{
    padding: 1.6rem;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.selectOptionDrawer_closeIcon{
    width: 2rem;
    height: 2rem;
}
.selectOptionDrawer_body{
    overflow-y: scroll;
    position: absolute;
    top: 5.2rem;
    left: 0;
    bottom: 0;
    right: 0;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.selectOptionDrawer_body::-webkit-scrollbar {
    display: none;
}
.selectOptionDrawer_select_rowBox{
    height: 5.6rem;
    border-bottom: 0.1rem solid #EEE;
    display: flex;
    align-items: center;
    gap: 1rem;
}
.profile_userVehicle_list_bottom{
    background-color: var(--bottomNavigation-bg);
    box-shadow: 0rem -0.4rem 1.3rem 0rem rgba(153, 216, 234, 0.48);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 6.5rem;
    gap: 0.9rem;
}
.profile_userVehicle_list_bottom_button{
    flex: 1;
    background-color: var(--color-blue);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4.7rem;
    border-radius: 3rem;
    gap: 0.5rem;
}

/* modal */
.calendar_modal{
    padding: 2rem;
}
.calendar_modal_buttonRow{
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    gap: 2rem;
}

/* profile vehicle detail */
.profileVehicle_Box_overview_title{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.profileVehicle_Box_overview_rightRow{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.profileVehicle_Box_overview_qrButton{
    background-color: var(--color-lightRed2);
    border-radius: 3rem;
    height: 3rem;
    width: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.profileVehicle_Box_overview_editButton{
    background-color: var(--color-lightRed2);
    padding: 0.3rem 1.2rem;
    border-radius: 3rem;
}
.profileVehicle_Box_overview_body_row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
}
.profileVehicle_Box_overview_body_row_left{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
}
.profileVehicle_Box_overview_body_row_image{
    width: 3rem;
    height: 3rem;
}
.profileVehicle_Box_overview_tyre{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2.6rem;
    margin-bottom: 2.6rem;
}
.profileVehicle_Box_overview_tyre_vehicleBoneImage{
    width: 22.1rem;
    height: 28.1rem;
    position: relative;
}
.profileVehicle_Box_overview_tyre_vehicleBoneImage_tyreText{
    position: absolute;
    width: 9rem;
    display: flex;
    justify-content: center;
}
.profileVehicle_Box_overview_tyre_vehicleBoneImage_tyreText_leftTop{
    top: -2.5rem;
    left: -2rem;
}
.profileVehicle_Box_overview_tyre_vehicleBoneImage_tyreText_rightTop{
    top: -2.5rem;
    right: -2rem;
}
.profileVehicle_Box_overview_tyre_vehicleBoneImage_tyreText_leftBottom{
    bottom: -2.5rem;
    left: -2rem;
}
.profileVehicle_Box_overview_tyre_vehicleBoneImage_tyreText_rightBottom{
    bottom: -2.5rem;
    right: -2rem;
}
.profileVehicleCarPlate_position{
    position: absolute;
    bottom: 45px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
}

/* voucher */
.voucherBody{
    background-image: url('/assets/images/voucher_bg.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top center;
}
.voucherBody_top{
    height: 20rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.voucherBody_top_row{
    display: flex;
    align-items: center;
    gap: 0.6rem;
}
.voucherBody_top_icon{
    width: 2.8rem;
    height: 2.8rem;
}
.voucherBody_bg_object_1{
    position: absolute;
    left: 0;
    bottom: -4.2rem;
    width: 13rem;
    height: 16.4rem;
    z-index: 2;
}
.voucherBody_bg_object_2{
    width: 23.3rem;
    height: 25.9rem;
    right: 0;
    position: absolute;
    top: 0;
}
.voucherBody_bg_object_3{
    position: absolute;
    bottom: 0rem;
    left: 9.7rem;
    z-index: 3;
    width: 6rem;
    height: 5.8rem;
}
.voucherBody_center{
    width: 100%;
    border-radius: 2rem;
    box-shadow: 0rem 0rem 1rem 0rem rgba(125, 201, 254, 0.20);
    background-color: #FFFAF3;
    /* padding-top: 1.3rem; */
    padding-top: 0.1rem;
    margin-top: -2rem;
    z-index: 5;
    position: relative;
    min-height: 35rem;
}
.voucherBody_vouchers{
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2.2rem 1.6rem;
}
.voucherBody_vouchers_empty, .voucherBody_myRedemption_empty{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.voucherBody_vouchers_item{
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 0rem 0rem 1rem 0rem rgba(255, 220, 220, 1);
    border-radius: 2rem;
    overflow: hidden;
}
.voucherBody_vouchers_item_image{
    overflow: hidden;
    width: 100%;
    padding-top: 100%;
    position: relative;
    background-color: var(--color-lightGrey);
}
.voucherBody_vouchers_item_image img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}
.voucherBody_vouchers_item_content{
    display: flex;
    flex-direction: column;
    padding: 1.2rem;
    justify-content: space-between;
    flex: 1;
}
.voucherBody_myRedemption{
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    padding-bottom: 2rem;
    padding-top: 2rem;
}
.voucher_item_outlet{
    padding: 0.5rem;
    overflow: hidden;
}
.voucher_item{
    position: relative;
    width: 100%;
    height: 11.8rem;
    border-radius: 2rem;
    box-shadow: 0rem 0rem 1rem 0rem rgba(255, 220, 220, 1);
    padding: 0.9rem 3rem 0.9rem 0.9rem;
    background-color: #FFFFFF;
}
.voucher_item_positionRound{
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    right: -2rem;
    top: 50%;
    transform: translateY(-2rem);
    background-color: white;
    background-image: 
        radial-gradient(circle, rgba(255,255,255,1) 40%, rgba(255,255,255,0) 100%),
        linear-gradient(90deg, rgba(255, 220, 220, 1) 49%, rgba(255, 220, 220, 0) 65%);
}
.voucher_item_content{
    display: flex;
    align-items: stretch;
    gap: 1.8rem;
}
.voucher_item_content_image{
    width: 10rem;
    height: 10rem;
    position: relative;
    overflow: hidden;
    border-radius: 1rem;
    background-color: var(--color-lightGrey)
}
.voucher_item_content_image img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}
.voucher_item_content_right{
    display: flex;
    flex-direction: column;
    flex: 1;
}
.voucher_item_content_right_titleBox{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.voucherDetail{

}
.voucherDetail_image{

}
.voucherDetail_container{
    padding-bottom: 2rem;
}
.voucherDetail_title{
    margin-top: 2rem;
}
.voucherDetail_row1{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
}
.voucherDetail_bottomNavigation{
    height: 6.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.voucherDetail_bottomNavigation_button{
    flex: 1 1;
    background-color: var(--color-red);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4.5rem;
    border-radius: 3rem;
    position: relative;
}

/** Comment related CSS START **/
.comment_textInput{
    height: 2rem;
    background-color: #F2F2F2;
    border-radius: 3rem;
    padding: 1rem 2rem;
    border-color: transparent;
}
.comment_textInput:focus{
    border-color: transparent;
}
.comment_textInput:disabled{
    background-color: #F2F2F2;
    opacity: 1;
}
/** Comment related CSS END **/
.commentBoxOuter{
    display: flex;
    width: 100%;
}
.commentBoxText{
    width: 100%;
    padding-top: 0.5rem;
    padding-bottom: 1.2rem;
    flex: 1;
}
.commentBoxFakeInput{
    height: 4rem;
    background-color: #F2F2F2;
    border-radius: 3rem;
    padding: 1rem 2rem;
    border-color: transparent;
    display: flex;
    align-items: center;
}
.commentBoxIconList{
    display: flex;
    padding-left: 3rem;
}
.commentBoxIcon{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
}
.commentBoxImage{
    width: 2.5rem;
    height: 2.5rem;
}
.commentDrawer_root{
    position: absolute;
    z-index: 9;
    top: -1px;
}
.commentDrawer_drawer{
    position: absolute;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
}
.commentDrawer_header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.commentDrawer_innerHeader{
    margin: 1rem;
    display: flex;
    width: 100%;
    border-bottom: 0.1rem solid #DEDEDE;
    justify-content: space-between;
}
.commentDrawer_likeCount{
    padding: 2rem 1rem;
}
.commentDrawer_headerButton{
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
}
.commentDrawer_headerLikeButton{
    display: flex;
    padding: 1rem 2rem;
    border-radius: 2.5rem;
    background-color: var(--color-lightBlue5);
    grid-gap: 0.5rem;
}
.commentDrawer_headerImage{
    width: 2rem;
    height: 2rem;
}
.commentDrawer_headerShareButton{
    display: flex;
    padding: 1rem 2rem;
    border-radius: 2.5rem;
    background-color: #E91C21;
    grid-gap: 0.5rem;
}
.commentDrawer_closeBox{
    padding: 1.6rem;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.commentDrawer_closeIcon{
    width: 2rem;
    height: 2rem;
}
.commentDrawer_body{
    overflow-y: scroll;
    position: absolute;
    top: 8rem;
    left: 0;
    bottom: 6.5rem;
    right: 0;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.commentDrawer_box{
    padding: 2rem 1.5rem;
}
.commentDrawer_body::-webkit-scrollbar {
    display: none;
}
.commentDrawer_commentEntry{
    display: flex;
    padding: 1rem 0;
    grid-gap: 0.5rem;
}
.commentDrawer_commentReply{
    width: 5rem;
}
.commentDrawer_commentDetail{
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: var(--color-lightBlue5);
    border-radius: 1rem;
    padding: 1rem;
}
.commentDrawer_commentDateLikeRow{
    display: flex;
    justify-content: space-between;
}
.commentDrawer_commentDateLikeBox{
    display: flex;
    align-items: center;
    grid-gap: 1.5rem;
}
.commentDrawer_commentLikeReplyBox{
    display: flex;
    grid-gap: 1rem;
}
.commentDrawer_commentLikeReplyStat{
    display: flex;
    align-items: center;
    grid-gap: 0.2rem;
}
.commentDrawer_StatImage{
    width: 2rem;
    height: 2rem;
    padding: 0.2rem;
}
.commentBox_Sec{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 6.5rem;
    z-index: 3;
    background: #ffffff;
    box-shadow: 0rem 0rem 1rem 0rem rgba(125, 201, 254, 0.7);
}
.commentBox_ReplyChild{
    display: flex;
    padding: 0.5rem;
    align-items: center;
    grid-gap: 0.3rem;
}
.commentBox_ReplyChild_closeIcon{
    width: 1.8rem;
    height: 1.8rem;
}
.commentBox_ReplyRow{
    display: flex;
    width: 100%;
    align-items: center;
}
.commentBox_ReplyButton{
    display: flex;
    padding-left: 1rem;
}
.commentBox_ReplyButtonInner{
    background-color: #E91C21;
    padding: 1rem 2rem;
    border-radius: 3rem;
}


.accountLog_header{
    display: flex;
    align-items: center;
    gap: 2rem;
    padding-top: 2rem;
    padding-bottom: 4rem;
    /* background-color: #378CE7; */
    background-color: white;
}
.accountLog_header_image{
    width: 10rem;
    height: 10rem;
    padding: 2rem;
}
.accountLog_header_row{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.accountLog_header_row_pointBox{
    padding: 0.8rem;
    border-radius: 0.4rem;
    /* background-color: var(--color-lightGrey); */
    background-color: #F9F9F9;
}
.accountLog_body{
    padding-top: 2rem;
    padding-bottom: 2rem;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    overflow: hidden;
    margin-top: -2rem;
    min-height: 50rem;
    /* background: linear-gradient(150deg, var(--color-blue) 30%, var(--color-lightBlue2) 70%, #FFFFFF 100%); */
    /* background: linear-gradient(150deg, #5356FF 30%, #67C6E3 70%, #FFFFFF 100%); */
    background: linear-gradient(150deg, #DFF5FF 30%, #FFFFFF 100%);
    /* background-color: #DFF5FF; */
    
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}
.accountLog_body_item{
    background-color: #FFFFFF;
    padding: 1.2rem;
    border-radius: 1rem;
    box-shadow: 0rem 0rem 1.2rem 0rem rgb(55, 140, 231, 0.2);
    display: grid;
    grid-template-columns: 1fr 9rem;
    gap: 1.2rem;
}
.accountLog_body_item_left{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.accountLog_body_item_right{
    display: flex;
    align-items: center;
    justify-content: center;
}

/** Comment related CSS END **/

/* contact us */
.contactUsContainer {
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
}
.contactUsContent{
    height: 100%;
    width: 100%;
    padding-top: 1.9rem;
    padding-bottom: 2rem;
}